import React, {useEffect, useRef, useState} from 'react';
import MaskedInput from 'react-text-mask';
import ReCAPTCHA, {ReCAPTCHA as ReCAPTCHA2} from 'react-google-recaptcha';
import {useHistory} from 'react-router-dom';

import {siteKey} from 'env/env.json';

import {useSelector} from 'react-redux';
import {IStore} from 'store/store';
import {useAppDispatch} from 'utils/hooks';
import {showPopup} from 'store/components/PopupSlice';
import {activationEko, clearData, getBalanceEko} from 'store/components/EKOSlice';

import TipContent from './components/TipContent';
import Button from 'components/button/Button';
import CardData from './components/cardData/CardData';

import './ActivationEKO.scss';

const ActivationEKO = () => {
  const recaptchaRef = React.createRef<ReCAPTCHA2>().current;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const cardData = useSelector((state: IStore) => state.eko.data);
  const cardLoading = useSelector((state: IStore) => state.eko.loading);

  const [captcha, setCaptcha] = useState<string>('');
  const [card, setCard] = useState('');
  const [phone, setPhone] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [showBalance, setShowBalance] = useState(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const checkBalance = async () => {
    setLoading(true);
    if (!loading) {
      // @ts-ignore
      grecaptcha.reset();
      setCaptcha('');

      const {payload} = await dispatch(getBalanceEko({code: card, captcha_response: captcha}));

      if (payload?.status === 0) {
        setShowBalance(false);
        await setLoading(false);
        dispatch(showPopup('EKOPopup'));
      }
    }
  };

  const activateEKO = async () => {
    setLoading(true);

    if (!cardLoading) {
      const {payload} = await dispatch(activationEko(
        {
          card: card,
          type: 'phone',
          phone: phone,
          code: secretCode
        }
      ));
      if (payload[0].status === 1) {
        setPhone('');
        setCard('');
        setCaptcha('');
        setSecretCode('');
        setShowBalance(false);
      }

      await setLoading(false);
      await dispatch(showPopup('EKOPopup'));
    }
  };

  useEffect(() => {
    if (cardData?.valid_to) {
      setShowBalance(true);
    }
  }, [cardData]);

  useEffect(() => {
    dispatch(clearData());
    setPhone('');
    setCard('');
    setCaptcha('');
    setSecretCode('');
    setShowBalance(false);
  }, [history.location]);

  const inputValue = ({target: {value}}: any, type: 'phone' | 'card' | 'secretCode') => {
    console.log(value);
    const setters = {
      'card': {setter: setCard, len: 10},
      'phone': {setter: setPhone, len: 18},
      'secretCode': {setter: setSecretCode, len: 8}
    };
    if (/^[0-9]+$/i.test(value) && value.length <= setters[type].len) {
      setters[type].setter(value);
    }
    if (type === 'phone' && value.length <= setters[type].len) {
      setters[type].setter(value);
    }
    if (type === 'secretCode' && value.length <= setters[type].len) {
      setters[type].setter(value.toUpperCase());
    }
    if (value.length === 0) {
      setters[type].setter('');
    }
  };

  const enabledCheckCard = captcha && card && card.length >= 10 && !error.length;
  const enabledActivation = phone && card;

  const maskInit = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

  const changeCaptcha = (token: string | null) => {
    if (token) {
      setCaptcha(token);
    }
  };

  return (
    <div className={'activation-eko'}>
      <div className={'activation-eko__container fixed-container'}>
        <h2 className={'activation-eko__title'}>
          Активация единой карты оплаты мобильной связи
        </h2>
        <h3 className={'activation-eko__subtitle'}>
          Перед активацией карты, пожалуйста, проверьте баланс и срок действия:
        </h3>
        <div className={'activation-eko__content-container'}>
          <div className={'activation-eko__form '}>
            <TipContent />
            <div className={'activation-eko__form-container'}>
              <div>
                <span className={'activation-eko__step'}>
                  Проверить баланс
                  <br/>
                  Вашей карты:
                </span>
                <input
                  onInput={(event) => inputValue(event, 'card')}
                  value={card}
                  type="text"
                  className={'activation-eko__input'}
                  placeholder={'Введите номер карты'}
                />
              </div>
              <div className={'activation-eko__captcha'}>
                <div className={'activation-eko__captcha-item'}>
                  <ReCAPTCHA
                    // @ts-ignore
                    ref={recaptchaRef}
                    size={window.innerWidth < 450 ? 'normal' : 'normal'}
                    onChange={changeCaptcha}
                    onExpired={() => setCaptcha('')}
                    sitekey={siteKey}
                  />
                </div>
              </div>
              <Button
                loaderColor={'#4661C4'}
                className={'activation-eko__button'}
                onClick={checkBalance}
                loading={cardLoading}
                disabled={!enabledCheckCard}>
                Проверить баланс
              </Button>
            </div>
            {
              showBalance
                ? (
                  <>
                    <CardData balance={cardData.balance} date={cardData.valid_to} />
                    <div className={'activation-eko__tip-container activation-eko__tip-container_mobile'}>
                      <p className={'activation-eko__tip-list-item'}>
                        <b>Ограничения пополнения:</b>
                      </p>
                      <ul className={'activation-eko__tip-list'}>
                        <li>
                          Используя ЕКО, можно пополнить только баланс мобильного телефона
                        </li>
                        <li>
                          После истечения срока действия сертификат не принимается к оплате, сумма на нем не возвращается.
                        </li>
                        <li>
                          При утери или повреждении сертификата действуют правила, аналогичные истечению срока действия.
                        </li>
                      </ul>
                    </div>
                    <div className={'activation-eko__form activation-eko__form-payment'}>
                      <div className={'activation-eko__form-container'}>
                        <div>
                          <span className={'activation-eko__step'}>
                            Пополнение
                            <br/>
                            мобильной связи
                          </span>
                          <MaskedInput
                            value={phone}
                            onChange={(event) => inputValue(event, 'phone')}
                            type={'text'}
                            placeholder={'Введите номер телефона'}
                            className={'activation-eko__input'}
                            mask={maskInit}
                          />
                          <input
                            onInput={(event) => inputValue(event, 'secretCode')}
                            value={secretCode}
                            type="text"
                            className={'activation-eko__input'}
                            placeholder={'Введите секретный код'}
                          />
                        </div>
                        <Button
                          loaderColor={'#4661C4'}
                          loading={cardLoading}
                          disabled={!enabledActivation}
                          className={'activation-eko__button activation-eko__step2'}
                          onClick={activateEKO}>
                          Активировать
                        </Button>
                      </div>
                    </div>
                  </>
                ) : null
            }
            <p className={'activation-eko__accept-rules'}>
              Нажимая на кнопку Активировать, я даю&nbsp;
              <a
                target={'_blank'}
                className={'activation-eko__accept-rules-link'}
                href="/politika-personalnih-dannyh_new.pdf">
                согласие на обработку
                <br/>
                персональных данных
              </a>
            </p>
            <div className={'activation-eko__rules activation-eko__rules_mobile'}>
              <p>
                <b>Внимание!</b> Списание средств с карты производится единоразово, и в размере всего номинала.
                На один номер возможно активировать не более 15-ти кодов в сутки.
              </p>
              <p>
                Минимальный срок зачисления денежных средств на счёт составляет 24 часа.
                Максимальное время ожидания может достигать 5 рабочих дней.
              </p>
            </div>
          </div>

          <div className={'activation-eko__tip-content activation-eko__tip-content_desktop'}>
            <div
              className={'activation-eko__tip-container activation-eko__tip-container_active'}>
              <p className={'activation-eko__tip-list-item'}><b>1. Для пополнения счета мобильного оператора:</b></p>
              <ul className={'activation-eko__tip-list'}>
                <li>
                  Введите номер вашей карты ЕКО для проверки баланса.
                </li>
                <li>
                  Подтвердите, что вы не робот.
                </li>
                <li>
                  Нажмите на кнопку «Проверить баланс» для проведения проверки.
                </li>
                <li>
                  Введите номер телефона, на который будет произведена оплата (без 8 или +7).
                </li>
                <li>
                  Введите код карты под защитным слоем (без ЕКК).
                </li>
                <li>
                  Нажмите на кнопку «Активировать».
                </li>
                <li>
                  Зачисление денежных средств происходит единоразово и полностью в течение 24 часов.
                </li>
              </ul>
            </div>
            {
              showBalance
                ? (
                  <>
                    <div className={'activation-eko__line'} />
                    <div className={'activation-eko__tip-container'}>
                      <p className={'activation-eko__tip-list-item'}>
                        <b>Ограничения пополнения:</b>
                      </p>
                      <ul className={'activation-eko__tip-list'}>
                        <li>
                          Используя ЕКО, можно пополнить только баланс мобильного телефона
                        </li>
                        <li>
                          После истечения срока действия сертификат не принимается к оплате, сумма на нем не возвращается.
                        </li>
                        <li>
                          При утери или повреждении сертификата действуют правила, аналогичные истечению срока действия.
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null
            }
            <div className={'activation-eko__rules activation-eko__rules_desktop'}>
              <p>
                <b>Внимание!</b> Списание средств с карты производится единоразово, и в размере всего номинала.
                На один номер возможно активировать не более 15-ти кодов в сутки.
              </p>
              <p>
                Минимальный срок зачисления денежных средств на счёт составляет 24 часа.
                Максимальное время ожидания может достигать 5 рабочих дней.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationEKO;
