import React from 'react';

import arrow from '../images/arrow.svg';

interface ILinkContainer {
  showPhoneInput: boolean,
  rememberCardNumber: boolean,
  setShowPhoneInput: React.Dispatch<React.SetStateAction<boolean>>,
  setRememberCardNumber: React.Dispatch<React.SetStateAction<boolean>>,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
}

const LinkContainer = ({
  setShowPhoneInput,
  showPhoneInput,
  rememberCardNumber,
  setRememberCardNumber,
  setTitle,
  setError,
}: ILinkContainer) => {
  const rememberCard = () => {
    setShowPhoneInput(true);
    setTitle('Восстановление номера сертификата');
    setRememberCardNumber(true);
    setError('');
  };

  const changeForm = () => {
    setShowPhoneInput(false);
    setRememberCardNumber(false);
    setError('');
  };

  return (
    <>
      {showPhoneInput && rememberCardNumber ? (
        <div className={'phone-form__link-container'}>
          <img src={arrow} alt="arrow" className={'phone-form__arrow'}/>
          <a
            onClick={changeForm}
            className={'phone-form__link'}>
            Обычная активация
          </a>
        </div>
      ) : null}
      {!showPhoneInput ? (
        <a
          onClick={rememberCard}
          className={'phone-form__link phone-form__link_bottom-margin'}>
          Забыли номер сертификата?
        </a>
      ) : null}
    </>
  );
};

export default LinkContainer;
