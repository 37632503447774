import React, {useEffect, useState} from 'react';

import {hidePopup} from 'store/components/PopupSlice';
import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {getUser, requestOnChangeUserData, requestOnRepeatCode, requestOnSendCode} from 'store/components/ProfileSlice';

import Popup from 'components/popup/Popup';
import Input from 'components/input/Input';
import Button from 'components/button/Button';
import SecondInput from 'components/secondInput/SecondInput';

import './ChangeUserData.scss';

const ChangeUserData = () => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState<number>(61);
  const [sendType, setSendType] = useState(true);

  const [showCode, setShowCode] = useState(false);

  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  const type = useAppSelector(state => state.popup.props);
  const user = useAppSelector(state => state.auth.user);

  const variantSMS = 'в сообщении';
  const variantCall = 'по звонку';

  const onClickAccept = () => {
    dispatch(requestOnChangeUserData({
      changedData: type === 'phone'
        ? phone.replace(/\s|[()]/g, '')
        : email,
      type: type})).then(({payload})=> {
      const resObj = {
        res: {
          code: '',
          result: ''
        }
      };
      if (payload.data) {
        resObj.res = payload.data.data;
      } else if (payload.response) {
        const {code, result} = payload.response.data.data;
        resObj.res = {
          code: code,
          result: result[type][0],
        };
      }
      if (resObj.res.code === 'error') {
        setError(resObj.res.result);
        setTimeout(() => setError(''), 3000);
      }
      if (resObj.res.code === 'ok') {
        setShowCode(true);
      }
    });
    setTimer(61);
  };

  useEffect(() => {
    if (error === 'Количество попыток превысило лимит, запросите новый код') {
      setTimer(300);
    }
  }, [error]);

  const onSendCode = () => {
    dispatch(requestOnSendCode({
      code: code,
      type: type
    })).then(({payload: {data: {data: {result, code}}}})=> {
      if (code === 'error') {
        setError(result);
      }
      if (code === 'ok') {
        dispatch(hidePopup('changeUserData'));
        onClose();
        dispatch(getUser());
      }
    });
  };

  const disabledSendCode =
    ((type === 'phone' && code.length === 4)
    || (type === 'email' && code.length === 5))
    && timer === 0;

  const repeatSendCode = (sendCodeType: boolean) => {
    dispatch(requestOnRepeatCode({
      send_type: sendCodeType ? 'call' : 'sms',
      type: type
    })).then(({payload: {data: {data: {result, code}}}})=> {
      if (code === 'error') {
        setError(result);
      }
    });
    setTimer(61);
  };

  const change = (value: string) => {
    if (type === 'phone') {
      setPhone(value);
    }
    if (type === 'email') {
      setEmail(value);
    }
  };

  const changeTypetSendCode = () => {
    setSendType(!sendType);
    repeatSendCode(!sendType);
    setTimer(61);
  };

  useEffect(() => {
    const counter =
      setInterval(() => setTimer(timerInner => timerInner - 1), 1000);

    if (timer < 0) {
      clearInterval(counter);
    }

    return () => clearInterval(counter);
  }, [timer]);

  const onClose = () => {
    setError('');
    setShowCode(false);
    setPhone('');
    setEmail('');
    setCode('');
  };

  const mayPhone = type === 'phone'
    || !user?.email_verified_at
    || !user?.email;

  const codeMask = mayPhone ? [/\d/, /\d/, /\d/, /\d/] : [/./, /./, /./, /./, /./, /./];

  const minutes = Math.floor(timer / 60);
  const secundos =  Math.floor(timer % 60);

  return (
    <Popup id={'changeUserData'} className={'change-user-data'} onClose={onClose}>
      <div className={'change-user-data__container'}>
        <h2 className={'change-user-data__title'}>Смена {type === 'phone' ? 'номера телефона' : 'почты'}</h2>
        {!showCode ? (
          <>
            <SecondInput
              value={type === 'phone' ? phone : email}
              className={'change-user-data__second-input'}
              setValue={change}
              marginBottom={16}
              error={error}
              // onBlur={() => onBlueError('email')}
              // error={errorEmail}
              title={type === 'phone' ? 'Новый номер телефона' : 'Новый E-mail для получения сертификата'}
              isMask={type === 'phone'}
              type={type}
            />
            <Button onClick={onClickAccept} className={'change-user-data__button'}>
              Подтвердить
            </Button>
          </>
        ) : (
          <>
            <Input
              setValue={setCode}
              value={code}
              marginBottom={20}
              error={error}
              setError={setError}
              mask={codeMask}
              paddingBottom={10}
              placeholder={`Код - ${mayPhone 
                ? '4 цифры' 
                : '6 символов'
              }`}
            />
            <p className={'code-form__number'}>
              {`Введите ${
                mayPhone 
                  ? 'последние 4 цифры с входящего звонка который поступит в течение минуты на ' 
                  : '6 символов из письма, отправленного Вам на почту - '
              }`}
              <span
                className={'code-form__number_non-wrap'}>
                {
                  mayPhone
                    ? user?.phone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5')
                    : user?.email
                }
              </span>
            </p>
            <Button disabled={disabledSendCode} onClick={onSendCode} className={'change-user-data__button'}>
              Отправить код
            </Button>
            {
              timer > 0 ? (
                <p className={'timer__counter'}>
                  Отправить код повторно можно будет через 0{`${minutes}:${secundos > 9 ? secundos : '0' + secundos}`}
                </p>
              ) : (
                <>
                  <a
                    className={
                      timer < 0
                        ? 'timer__link timer__link_active'
                        : 'timer__link'
                    }
                    onClick={() => repeatSendCode(sendType)}>
                    Отправить еще раз
                  </a>
                  {mayPhone ? (
                    <Button
                      width={'100%'}
                      className={'timer__button'}
                      backgroundColor={'#fff'}
                      color={'#4661c4'}
                      disabled={disabledSendCode}
                      onClick={changeTypetSendCode}>
                      Получить код {sendType ? variantSMS : variantCall}
                    </Button>
                  ) : null }

                </>
              )
            }
          </>
        )}
      </div>
    </Popup>
  );
};

export default ChangeUserData;
