import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import ax from 'utils/ax';
import {downloadPDF} from 'utils/downloadPDF';
import {hidePopup, showPopup} from './PopupSlice';
import {IPurse} from './AuthSlice';
import {getUser} from './ProfileSlice';
import {store} from '../store';

export interface ICardsInOrders {
  id: number;
  amount: number;
  guid: string;
  order_id: string;
  markup_nominal: number;
  nominal: number;
  card_number: string;
  name: string | null;
  card_id: string;
  status: number;
  status_title: string;
  sum: number;
  created_at: string;
  updated_at: number;
  link: string;
  is_changed?: boolean;
}

export interface IHistoryOrders {
  id: string;
  order: {
    user_id: number;
    id: number;
    id1c: string;
    number: string;
    status: number;
    created_at: string;
    sum: number;
    is_changed?: boolean;
    positions: ICardsInOrders[];
  }
}

export interface IActivationHistory {
  number: string;
  nominal: number | string;
  activation_date: string;
}

export interface IDashboard {
  purse: IPurse | null;
  showMoneyTransfer: boolean;
  historyOrders: IHistoryOrders[];
  activeTabMenu: number;
  visibleMenu: boolean;
  activationHistory: {
    list: IActivationHistory[],
    loading: boolean,
  };
}

export const activeCard = createAsyncThunk(
  'dashboard/activeCard',
  async (payload: {card: string; pin: string}, {getState, dispatch}) => {
    const {auth}: any = getState();

    try {
      const {data} = await ax().post('api/cards/activate', {...payload, user_id: auth.user.id});

      if (data.data.code !== 'error') {
        dispatch(hidePopup('activationCard'));
        dispatch(showPopup('completedActivationCard'));
        dispatch(getUser());
      }

      return data.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  }
);

export const downloadCetificate = async ({name, id}: {name: string, id: number}) => {
  try {
    const response = await ax().get(`/api/file/download/card-template/${id}`, {responseType: 'blob'});
    if (response.data?.code === 429 || (response.message && response.message === 'Request failed with status code 429')) {
      store.dispatch(showPopup('tooManyAttempts'));
      return;
    }
    if (response.data.code === 'error') {
      return;
    }
    const bl = await new Blob([response.data], {type: 'application/pdf'});
    const url = URL.createObjectURL(bl);
    downloadPDF(url, name);
  } catch (e) {
    console.log(e);
  }
};

export const getHistoryOrders = createAsyncThunk(
  'dashboard/getHistoryOrders',
  async () => {
    try {
      const {data} = await ax().post('api/orders/get-orders');

      const code = data.data.code;

      if (code === 'error') {
        console.log(code);
      } else {
        return data.data.result[0];
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const getActivationHistory = createAsyncThunk(
  'dashboard/getActivationHistory',
  async () => {
    try {
      const {data} = await ax().post('api/cards/get-activation-history');
      const code = data.data.code;

      if (code === 'error') {
        console.log(code);
      } else {
        return data.data.result.cards;
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const createOrder = createAsyncThunk(
  'dashboard/createOrder',
  async (payload, {getState, dispatch}) => {
    const {dashboard, auth}: any = getState();
    try {
      const {data} = await ax().post('api/orders/create-order', {purse_id: dashboard.purse.id, is_required_email:  auth.is_required_email});
      if (data.data.code === 'error') {
        dispatch(showPopup('rejectOrder'));
      } else {
        dispatch(showPopup('completedOrder'));
        return data;
      }
    } catch (e) {
      console.log('error');
    }
  }
);

export const fastOrder = createAsyncThunk(
  'dashboard/fastOrder',
  async (payload: {total: number, nominal: number, guid: string}, {getState, dispatch}) => {
    const {dashboard, auth}: any = getState();
    try {
      const {data} = await ax().post('api/orders/fast-order', {
        guid_eps: payload.guid,
        purse_id: dashboard.purse.id,
        total: Math.floor(payload.total),
        nominal: Math.floor(payload.nominal)
      });
      return data;
    } catch (e) {
      console.log('error');
    }
  }
);

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    purse: null,
    showMoneyTransfer: false,
    historyOrders: [],
    activeTabMenu: 0,
    visibleMenu: false,
    activationHistory: {
      list: [],
      loading: false,
    }
  },
  reducers: {
    setPurse(state, {payload}) {
      state.showMoneyTransfer =
        payload.bank_commission &&
        typeof payload.bank_commission === 'string'
        && typeof payload.phone_commission === 'string';
      state.purse = payload;
    },
    setActiveTabMenu(state, {payload}) {
      state.activeTabMenu = payload;
    },
    setVisibleMenu(state, {payload}) {
      state.visibleMenu = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistoryOrders.fulfilled, (state: IDashboard, action) => {
      if (action.payload) {
        const changeFormatResponse: any = action.payload.orders.map((item: any) => {
          return {id: item.id, order: item};
        });

        state.historyOrders = changeFormatResponse;
      }
    });
    builder.addCase(getActivationHistory.pending, (state: IDashboard) => {
      state.activationHistory.loading = true;
    });
    builder.addCase(getActivationHistory.fulfilled, (state: IDashboard, action) => {
      if (action.payload) {
        state.activationHistory.list = action.payload.filter((item: IActivationHistory) => item.nominal !== null);
      }
      state.activationHistory.loading = false;
    });
    builder.addCase(getActivationHistory.rejected, (state: IDashboard) => {
      state.activationHistory.loading = false;
    });
  },
});

export const {setPurse, setActiveTabMenu, setVisibleMenu} = DashboardSlice.actions;

export default DashboardSlice.reducer;
