import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ReCAPTCHA, {ReCAPTCHA as ReCAPTCHA2} from 'react-google-recaptcha';

import {siteKey} from 'env/env.json';
import {errorTimer} from 'utils/errorTimer';
import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {StorageItemsNames} from 'enums';

import {getCode, getPath} from 'store/components/AuthSlice';
import {hideAllExcept, hidePopup, showPopup} from 'store/components/PopupSlice';

import Input from 'components/input/Input';
import Button from 'components/button/Button';
import LinkContainer from './assets/components/LinkContainer';
import RulesCheckbox from 'components/rulesCheckbox/RulesCheckbox';

import './PhoneForm.scss';

interface IPhoneForm {
  phone: string;
  setPhone: any;
  cardNumber: string;
  setCardNumber: any;
  setShowCodeForm: any;
  showPhoneInput: boolean;
  rememberCardNumber: boolean;
  setShowPhoneInput: any;
  setRememberCardNumber: any;
  setAuthTitle?: React.Dispatch<React.SetStateAction<string>>,
  setAuthSubTitle?: React.Dispatch<React.SetStateAction<string>>,
  outsideDisabler?: boolean;
}

const PhoneForm: React.FC<IPhoneForm> = ({
  phone,
  setPhone,
  setShowCodeForm,
  cardNumber,
  setCardNumber,
  showPhoneInput,
  setShowPhoneInput,
  setRememberCardNumber,
  rememberCardNumber,
  setAuthTitle,
  setAuthSubTitle,
  outsideDisabler
}) => {
  const recaptchaRef = React.createRef<ReCAPTCHA2>().current;
  const href = window.location.href;
  const domainNameMTS = 'mtsfirst';
  const domainNameMakfa = 'makfa';
  const domainNameNLoto = 'loto';
  const domainNameSmeg = 'smeg';
  const domainNameProektirovanie = 'proektirovanie';
  const domainNameGeo = 'geo';
  const domainNameLocal = 'local';
  const domainNameBirthday = 'birthday';
  const domainNameRaif = 'reif';
  const domainNameBrucite = 'brucite';

  const dispatch = useAppDispatch();
  const {search} = useLocation();

  const visiblePopup = useAppSelector((state) => state.popup.popup);
  const currentDomainName = useAppSelector((state) => state.settings.currentDomainName);
  const allowAuth = useAppSelector((state) => state.settings.allowAuth);
  const test = localStorage.getItem('tester');

  const [captcha, setCaptcha] = useState<string>('');
  const [captchaError, setCaptchaError] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState('Авторизация');
  const [description, setDescription] = useState('Введите номер Универсального сертификата для авторизации');
  const [disableButtonPhoneCatch, setDisableButtonPhoneCatch] = useState(false);

  const disabledButtonPhone = phone.includes('_') || !phone || captcha === '';
  const disabledButtonCardCode = !cardNumber || cardNumber.includes('_');
  const showCheckBox = (
    href.includes(domainNameMTS)
    || href.includes(domainNameMakfa)
    || href.includes(domainNameNLoto)
    || href.includes(domainNameSmeg)
    || href.includes(domainNameBrucite)
    || href.includes(domainNameProektirovanie)
    || href.includes(domainNameLocal)
    || href.includes(domainNameBirthday)
    || href.includes(domainNameGeo)
  ) && !showPhoneInput;

  useEffect(() => {
    if (rememberCardNumber) {
      setTitle('Восстановление номера сертификата');
      setDescription('Введите номер телефона для\nпроверки его в базе');
      return;
    } else if (showPhoneInput) {
      setDescription('Войдите или зарегистрируйтесь по номеру телефона');
    } else {
      setDescription('Введите номер Универсального сертификата для авторизации');
    }
    setTitle('Авторизация');
  }, [visiblePopup['auth'], showPhoneInput]);

  const mayRedirect = () => {
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }
    setLoading(true);
    dispatch(getPath({cardNumber: +cardNumber}))
      .then(({payload: {data: {data}}}: any) => {
        if (data.code === 'error') {
          setTimeout(() => {
            setError(data.result);
            setTimeout(() => setError(''), 3000);
            setLoading(false);
          }, 500);
        } else {
          localStorage.setItem(StorageItemsNames.CARD_NUMBER, cardNumber);
          if (data.result.url){
            const location = window.location.origin;
            const regex = /\/$/;
            const modUrl = data.result.url.replace(regex, '');
            if (location !== modUrl) {
              window.location.replace(`${data.result.url}?card=${cardNumber}`);
            }
          }
          setTimeout(() => {
            setLoading(false);
            setShowPhoneInput(true);
            setTitle('Авторизация');
          }, 1000);
        }
      });
  };

  const openSmsPopup = () => {
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }

    setLoading(true);
    if (!loading){
      dispatch(getCode({phone, captcha}))
        .then(({payload}: any) => {
          // @ts-ignore
          window.grecaptcha.reset();
          setCaptcha('');
          if (payload.code === 'error') {
            setLoading(false);
            setError(payload.result);
            setTimeout(() => setError(''), 3000);
            setDisableButtonPhoneCatch(true);
            if (Object.keys(payload.result).includes('captcha_response')) {
              errorTimer(setCaptchaError, payload.result.captcha_response[0]);
            }
          } else {
            setTimeout(() => {
              setLoading(false);
              setShowCodeForm(true);
            }, 1000);
          }
        });
    }
  };

  const changeCaptcha = (token: string | null) => {
    if (token) {
      setCaptcha(token);
    }
  };

  const onKeyPressHandler = (event: any) => {
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }

    if (event.keyCode === 13) {
      if (showPhoneInput) {
        !disabledButtonPhone && openSmsPopup();
        return;
      }
      !disabledButtonCardCode && mayRedirect();
    }
  };

  const ButtonDisabled = () => {
    if (showPhoneInput) {
      return disabledButtonPhone || disableButtonPhoneCatch || !!error;
    } else {
      if (
        href.includes(domainNameMakfa)
        || href.includes(domainNameMTS)
        || href.includes(domainNameNLoto)
        || href.includes(domainNameSmeg)
        || href.includes(domainNameBirthday)
        || href.includes(domainNameRaif)
        || href.includes(domainNameProektirovanie)
        || href.includes(domainNameBrucite)
        || href.includes(domainNameGeo)
      ) {
        return disabledButtonCardCode || !!error || (outsideDisabler !== undefined ? outsideDisabler : !checkbox);
      } else {
        return disabledButtonCardCode || !!error;
      }
    }
  };

  useEffect(() => {
    setCaptcha('');
    if (search.includes('auth=open-auth&type=phone')) {
      dispatch(showPopup('auth'));
      setTimeout(() => { // без setTimeout не переключает на телефон
        setShowPhoneInput(true);
      });
    }
    if (!visiblePopup['auth']) {
      setShowPhoneInput(false);
    }
  }, [search, visiblePopup['auth']]);

  useEffect(() => {
    if (setAuthTitle) {
      setAuthTitle(title);
    }
    if (setAuthSubTitle) {
      setAuthSubTitle(description);
    }
  }, [title, description]);

  return (
    <div className={'phone-form'} onKeyUp={onKeyPressHandler}>
      <h3
        className={`
          phone-form__title 
          ${rememberCardNumber ? 'phone-form__title_large-size' : ''}
        `}>
        {title}
      </h3>
      <p className={'phone-form__description'}>
        {description}
      </p>
      {showPhoneInput ? (
        <Input
          error={error}
          setError={setError}
          placeholder={'Телефон'}
          onChange={() => setDisableButtonPhoneCatch(false)}
          type={'tel'}
          value={phone}
          setValue={setPhone}
          marginBottom={16}
          paddingBottom={10}
        />
      ) : (
        <Input
          error={error}
          type={'tel'}
          placeholder={'Номер сертификата'}
          setError={setError}
          value={cardNumber}
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          setValue={setCardNumber}
          marginBottom={16}
          paddingBottom={10}
        />
      )}
      <div className={'phone-form__action-container'}>
        <Button
          disabled={ButtonDisabled()}
          width={'100%'}
          loading={loading}
          marginBottom={showPhoneInput ? rememberCardNumber ? 24 : 30 : 12}
          onClick={showPhoneInput ? openSmsPopup : mayRedirect}>
          {rememberCardNumber ? 'Войти' : 'Войти'}
        </Button>
        {showPhoneInput ? (
          <div className={'register__captcha'}>
            <ReCAPTCHA
              // @ts-ignore
              ref={recaptchaRef}
              size={window.innerWidth < 450 ? 'compact' : 'normal'}
              onChange={changeCaptcha}
              onExpired={() => setCaptcha('')}
              sitekey={siteKey}
            />
            <span
              className={`register__captcha-error ${captchaError ? 'register__captcha-error_active' : ''}`}>
              {captchaError}
            </span>
          </div>
        ) : null}
        <RulesCheckbox
          showCheckBox={showCheckBox}
          checkbox={checkbox}
          setCheckbox={setCheckbox}
          currentDomainName={currentDomainName}
        />
        <LinkContainer
          showPhoneInput={showPhoneInput}
          rememberCardNumber={rememberCardNumber}
          setShowPhoneInput={setShowPhoneInput}
          setRememberCardNumber={setRememberCardNumber}
          setTitle={setTitle}
          setError={setError}
        />
      </div>
    </div>
  );
};

export default PhoneForm;
