import React from 'react';
import {useSelector} from 'react-redux';

import Title from 'components/title/Title';
import {IStore} from 'store/store';

import jivo from './assets/images/jivo.svg';
import telegram from './assets/images/telegram.svg';
import phone from './assets/images/phone.svg';
import email from './assets/images/email.svg';

import './Help.scss';

const Help = () => {
  const isNotPepsico = useSelector((state: IStore) => state.auth.is_required_email);

  const href = window.location.href;
  const isMegafon = href.includes('megafon');

  const openJivo = () => {
    // @ts-ignore
    jivo_api.open();
    return false;
  };

  return (
    <div className={'help'}>
      <Title>Помощь</Title>
      <p className={'help__subtitle'}>
        Если у Вас остались вопросы, обращайтесь:
      </p>
      <div className={'help__container'}>
        <div className={'help__chat-container'} onClick={openJivo}>
          <img src={jivo} alt="jivo" className={'help__img'} />
          <span className={'help__text'}>обращайтесь в Jivo-чат </span>
        </div>
        <a
          href={'https://t.me/presentcard_bot'}
          target={'_blank'}
          className={'help__chat-container'}
          rel="noreferrer">
          <img src={telegram} alt="telegram" className={'help__img'} />
          <span className={'help__text'}>в telegram чат: presentcard_bot</span>
        </a>
      </div>
      {!isNotPepsico ? (<a href="tel:+74994559935" className={'help__text'}>+7 (499) 455-99-35</a>) : null}
      {
        isMegafon ? (
          <>
            <a
              href="mailto:megafon@zakazpodarka.ru"
              target={'_blank'}
              className={'help__chat-container'}
              rel="noreferrer">
              <img src={email} alt="email" className={'help__img'} />
              <span className={'help__text'}>megafon@zakazpodarka.ru</span>
            </a>
            <a
              href="tel:+74951507003"
              target={'_blank'}
              className={'help__chat-container'}
              rel="noreferrer">
              <img src={phone} alt="phone" className={'help__img'} />
              <span className={'help__text'}>+7 (495) 150-70-03</span>
            </a>
          </>
        ): null
      }
    </div>
  );
};

export default Help;