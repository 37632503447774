import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {IStore} from 'store/store';
import {clearCart, getCart} from 'store/components/CartSlice';
import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import like from './assets/images/like.svg';

import './CompletedOrder.scss';

interface ICompleteOrderList {
  [key: string] : Set<string>
}

const CompletedOrder = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cart = useAppSelector(state => state.cart.cart);
  const completedOrder = useSelector((state: IStore) => state.popup.popup.completedOrder);

  const [filteredCart, setFilteredCart] = useState<ICompleteOrderList>({});

  useEffect(() => {
    if (cart.length) {
      const cartList: ICompleteOrderList = {};
      cart.forEach((item) => {
        if (!cartList[item.name]) {
          cartList[item.name] = new Set<string>();
        }
        if (item.is_reserved) {
          cartList[item.name].add('в течение 1 часа');
        } else {
          cartList[item.name].add(item.validity_period);
        }
      });
      setFilteredCart(cartList);
    }
  }, []);

  const CompletedOrderListItem = ({item}: { item: [string, Set<string>] }) => {
    const itemList = Array.from(item[1]);
    return (
      <>
        {itemList.map((value) => {
          return (
            <div key={value} className={'order-list__item'}>
              <span className={'order-list__name'}>
                <span
                  className={`
                    order-list__name_black
                    ${item[0].length > 10 ? 'order-list__name_hidden' : ''}
                  `}>
                  {item[0]}
                </span>
              </span>
              <span className={'order-list__validity_period'}>{value !== null ? value : 'В течении часа'}</span>
            </div>
          );
        })}
      </>
    );
  };

  const onClose = () => {
    history.push('prizes');
    dispatch(clearCart());
    dispatch(getCart());
  };

  return (
    <Popup id={'completedOrder'} className={'completed-order'} onClose={onClose}>
      <div className={'completed-order__container'}>
        <img src={like} alt="like" className={'completed-order__icon'} />
        <h2 className={'completed-order__title'}>Заказ оформлен</h2>
        <div className={'order-list__container'}>
          {Object.entries(filteredCart).map(item => <CompletedOrderListItem key={item[0]} item={item} />)}
        </div>
        <Timer popupStatus={{completedOrder}} onClose={onClose} />
      </div>
    </Popup>
  );
};

export default CompletedOrder;
