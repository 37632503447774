import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {transactionsData} from '../enums/mockData';

import ax from 'utils/ax';
import {data} from 'autoprefixer';

export interface IGetPayment {
  purse_id: number,
  sum: number,
  commission: number,
  type: string,
  phone?: string,
  card?: string,
}

export interface IPaymentHistoryItem {
  id: string,
  payment_type: 'phone' | 'card',
  amount: number,
  commission: number,
  status: number,
  recipient_number: string,
  created_at: string,
  status_label: string,
}

export interface IPayment {
  data: any,
  loading: boolean,
  dataHistory: IPaymentHistoryItem[],
  historyTotal: number,
  loadingHistory: boolean,
  error: string,
  errorHistory: string,
}

export const createPayment = createAsyncThunk(
  'moneyTransfer/createPayment',
  async (payload: IGetPayment, {dispatch}) => {
    try {
      const {data} = await ax().post('api/payments/create', payload);
      return data;
    } catch (e) {
      console.log('error');
    }
  }
);

export const getPaymentHistory = createAsyncThunk(
  'moneyTransfer/getPaymentHistory',
  async (payload, {dispatch}) => {
    try {
      const {data} = await ax().post('api/payments');
      console.log(data);
      return data;
      // return new Promise<any>((resolve) => {
      //   setTimeout(() => {
      //     resolve(transactionsData);
      //   }, 2345);
      // });
      // TODO позже надо убрать, но сейчас нужно для интеграции
    } catch (e) {
      console.log('error');
    }
  }
);

const MoneyTransferSlice = createSlice({
  name: 'moneyTransfer',
  initialState: {
    data: {},
    loading: false,
    dataHistory: [],
    historyTotal: 0,
    loadingHistory: false,
    error: '',
    errorHistory: '',
  },
  reducers: {
    clearError: (state: IPayment) => {
      state.error = '';
    },
    clearData: (state: IPayment) => {
      state.data = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createPayment.fulfilled, (state: IPayment, action) => {
      state.loading = false;
      if (action.payload.data.code === 'error' && action.payload.data.result) {
        state.error = action.payload.data.result;
      } else if (action.payload.data.code === 'ok') {
        state.data = action.payload.data;
      }
    });
    builder.addCase(createPayment.pending, (state: IPayment) => {
      state.loading = true;
    });
    builder.addCase(createPayment.rejected, (state: IPayment) => {
      state.loading = false;
    });
    builder.addCase(getPaymentHistory.fulfilled, (state: IPayment, action) => {
      state.loadingHistory = false;
      if (action.payload.data.code === 'error' && action.payload.data.result) {
        state.errorHistory = action.payload.data.result;
      } else if (action.payload.data.code === 'ok') {
        state.dataHistory = action.payload.data.result.items;
        state.historyTotal = action.payload.data.result.totla;
      }
    });
    builder.addCase(getPaymentHistory.pending, (state: IPayment) => {
      state.loadingHistory = true;
    });
    builder.addCase(getPaymentHistory.rejected, (state: IPayment) => {
      state.loadingHistory = false;
    });
  },
});
export const {clearError, clearData} = MoneyTransferSlice.actions;

export default MoneyTransferSlice.reducer;
