import React, {useState} from 'react';

import {useAppSelector} from 'utils/hooks';

import {GetIcon} from 'store/enums/normalizedIcons';
import Title from 'components/title/Title';
import ActivationHistoryItem from './assets/components/activationHistoryItem/ActivationHistoryItem';
import Button from 'components/button/Button';
import UpwardButton from 'components/upwardButton/UpwardButton';

import './ActivationHistory.scss';

const ActivationHistory = () => {
  const colors = useAppSelector((state) => state.settings.colors);
  const domain = useAppSelector((state) => state.settings.currentDomainName);
  const {list, loading} = useAppSelector(state => state.dashboard.activationHistory);

  const [step, setStep] = useState(1);

  const {loadButtonColor} = colors[domain];

  const stepLen = 12;

  const disabledNew = step * stepLen >= list.length && list.length > 0;
  const visibleCard = list.length >= (stepLen + 1) && (step * stepLen) < list.length;

  return (
    <div className={'activation-history'}>
      <div className={'activation-history__description'}>
        История активаций сертификатов.
      </div>
      {
        list.length
          ? (
            <>
              <div className={'activation-history__container'}>
                <div className={'activation-history__title-container'}>
                  <div className={'activation-history__info-text'}>
                    Номер сертификата
                  </div>
                  <div className={'activation-history__info'}>
                    <div className={'activation-history__info-text'}>
                      Номинал
                    </div>
                    <div className={'activation-history__info-text'}>
                      Дата активации
                    </div>
                  </div>
                </div>
                {
                  list
                    ? list.slice(0, step * 12).map((item) => <ActivationHistoryItem key={item.number} item={item} />)
                    : null
                }
              </div>
              {
                !loading && visibleCard ? (
                  <div className={'activation-history__button-container'}>
                    <Button
                      disabled={disabledNew}
                      className={'activation-history__button'}
                      icon={<GetIcon iconName={'LoadButton'} fill={loadButtonColor} secondaryFill={loadButtonColor} />}
                      onClick={() => setStep(step + 1)}>
                      Показать еще
                    </Button>
                  </div>
                ) : null
              }
              <UpwardButton />
            </>
          ) : (
            <h2 className={'activation-history__nothing'}>
              Активаций пока не было
            </h2>
          )
      }
    </div>
  );
};

export default ActivationHistory;
