import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {clearError, createPayment, clearData} from 'store/components/MoneyTransferSlice';
import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {openLink} from 'utils/openLink';
import {useAppDispatch, useAppSelector} from 'utils/hooks';

import Checkbox from 'components/checkbox/Checkbox';
import SecondInput from 'components/secondInput/SecondInput';
import Button from 'components/button/Button';
import Input from 'components/input/Input';

import arrow from '../../images/arrow.svg';

import './MoneyTransferTabs.scss';

const MAX_SUM_CARD = 14500;
const MAX_SUM_PHONE = 5000;

const MINIMUM_MARKUP = 50;

interface IBaseType {
  sum: string,
  setSum: (data: string) => void,
  setErrorSum: (data: string) => void,
  markup: string,
  setMarkup: (data: string) => void,
  balance?: number,
}

interface IPhoneAndCardComponent extends IBaseType {
  mask: (string | RegExp)[],
  value: string,
  setValue: (data: string) => void,
  title: string,
  commission: number,
  error: string
}

interface ISPB extends IBaseType {
  mask: (string | RegExp)[],
  phone: string,
  setPhone: (data: string) => void,
  activeBank: string,
  setActiveBank: (data: string) => void,
  commission: number,
}

const SumComponent = ({sum, setSum, markup, setMarkup, balance, isPhone, commission, setErrorSum}: any) => {
  const moreThanAvailable = parseInt(sum) + parseFloat(markup) > balance;
  const error =
    parseInt(sum) > (isPhone ? MAX_SUM_PHONE : MAX_SUM_CARD)
      ? `Максимальная сумма не более ${isPhone ? MAX_SUM_PHONE : MAX_SUM_CARD}`
      : moreThanAvailable
        ? 'Сумма не должна превышать доступные средства'
        : '';

  const changeSum = (value: string, type = 'nominal', placeholder = '') => {
    if (value < sum) {
      value = value.substring(0, value.length - (placeholder.length + 1));
    }

    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = `${cleanedValue} ${type === 'nominal' ? placeholder : placeholder}`;

    if (parseInt(cleanedValue) <= 0) {
      setSum('');
      return;
    }

    if (value.length) {
      setSum(formattedValue);
      setMarkup(Math.floor(parseInt(cleanedValue) / 100 * commission >= MINIMUM_MARKUP
        ? parseInt(cleanedValue) / 100 * commission
        : MINIMUM_MARKUP));
    } else {
      setSum('');
      setMarkup('');
    }
  };

  useEffect(() => {
    setErrorSum(error);
  }, [error]);

  return (
    <div className={'money-transfer-tabs__sum-component'}>
      <SecondInput
        title={'Введите сумму, ₽'}
        error={error}
        value={sum}
        setValue={changeSum}
        placeholder={`до ${isPhone ? balance > MAX_SUM_PHONE ? MAX_SUM_PHONE : balance : balance > MAX_SUM_CARD ? MAX_SUM_CARD : balance}`}
        className={'money-transfer-tabs__sum'}
        errorBG={'#FF4B40'}
        errorColor={'#FFDCE7'}
      />
      <SecondInput
        title={'Комиссия, ₽'}
        value={markup}
        setValue={setMarkup}
        placeholder={'0'}
        className={'money-transfer-tabs__markup'}
        disabled={true}
        errorColor={'#FFDCE7'}
      />
    </div>
  );
};

const SBP = ({
  sum,
  setSum,
  markup,
  setMarkup,
  mask,
  phone,
  setPhone,
  activeBank,
  setActiveBank,
  balance,
  setErrorSum,
  commission
}: ISPB) => {
  const [show, setShow] = useState(false);

  const arr = [
    {name: 'Райффайзен', active: false},
    {name: 'Тинькоф', active: false},
    {name: 'Сбер', active: false},
    {name: 'Альфа-банк', active: false},
    {name: 'Россельхоз банк', active: false},
  ];

  return (
    <div className={'money-transfer-tabs__spb'}>
      <div>
        <span className={'money-transfer-tabs__input-title'}>
          Номер телефона получателя
        </span>
        <Input background={'#FFFFFF'} placeholder={'+7 XXX XXX-XX-XX'} value={phone} setValue={setPhone} mask={mask} />
      </div>
      <SumComponent setErrorSum={setErrorSum} sum={sum} markup={markup} commission={commission} setSum={setSum} setMarkup={setMarkup} balance={balance}/>
      <div className={'money-transfer-tabs__selector-input'}>
        <span className={'money-transfer-tabs__input-title'}>
          Банк получателя
        </span>
        <div
          onClick={() => setShow(!show)}
          className={`
            money-transfer-tabs__selector-display 
            money-transfer-tabs__selector-display_${show ? 'active' : ''}
          `}>
          {activeBank
            ? activeBank
            : <span className={'money-transfer-tabs__selector-display_white'}>Выбрать из списка</span>
          }
          <img src={arrow} alt={'arrow'}/>
        </div>
        <div
          className={`
            money-transfer-tabs__selector-list 
            ${show ? 'money-transfer-tabs__selector-list_open' : ''}
          `}>
          {arr.map((item) => {
            return (
              <div
                onClick={() => {
                  setActiveBank(item.name);
                  setShow(false);
                }}
                className={'money-transfer-tabs__selector-item'}
                key={item.name}>
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const PhoneAndCard = (
  {
    sum,
    setSum,
    markup,
    setMarkup,
    mask,
    value,
    setValue,
    setErrorSum,
    title,
    balance,
    commission,
    error,
  }: IPhoneAndCardComponent) => {
  const isPhone = title === 'Номер телефона получателя';
  const placeholder = isPhone ? '+7 XXX XXX-XX-XX' : 'XXXX XXXX XXXX XXXX';

  return (
    <div>
      <div className={'money-transfer-tabs__data-container'}>
        <SecondInput
          error={error}
          title={title}
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          mask={mask}
          className={'money-transfer-tabs__phone-and-card'}
          errorBG={'#FF4B40'}
          errorColor={'#FFDCE7'}
          isMask={true}
        />
      </div>
      <SumComponent
        setErrorSum={setErrorSum}
        isPhone={isPhone}
        sum={sum}
        markup={markup}
        commission={commission}
        setSum={setSum}
        setMarkup={setMarkup}
        balance={balance}
      />
    </div>
  );
};

const MoneyTransferTabs = (
  {
    activeTab,
    setActiveTab,
    buttonInfo,
    setTransactionReady,
    transactionReady
  }:{
    activeTab: number,
    setActiveTab: (data: number) => void,
    buttonInfo: string,
    setTransactionReady: (data: boolean) => void,
    transactionReady: boolean,
  }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const purse = useAppSelector(state => state.dashboard.purse);
  const error = useAppSelector(state => state.payments.error);
  const data = useAppSelector(state => state.payments.data);

  const [phone, setPhone] = useState('');
  const [cardNum, setCardNum] = useState('');
  const [bank, setBank] = useState('');
  const [sum, setSum] = useState('');
  const [errorSum, setErrorSum] = useState('');
  const [markup, setMarkup] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [activeTabName, setActiveTabName] = useState('');


  const maskPhone = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
  const maskCard = [
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/];

  const personalData = activeTabName === 'Банковская карта'
    ? `${parseInt(cardNum.replaceAll(' ', ''))}`.length < 16
    : `${phone.replaceAll('_', '')}`.length < 18;

  const maxSum = activeTabName === 'Телефон'
    ? parseInt(sum) <= MAX_SUM_PHONE
    : parseInt(sum) <= MAX_SUM_CARD;
  const minSum = activeTabName === 'Телефон'
    ? parseInt(sum) >= 1
    : parseInt(sum) >= 10;

  const enabledButton = (minSum && maxSum) && !personalData && !error && !errorSum && (transactionReady ? checkbox : true);

  const showBankTabs = typeof purse?.bank_commission === 'string';
  const showPhoneTab = typeof purse?.phone_commission === 'string';

  const tabs = [
    {
      name: 'СБП',
      hidden: true,
      component: <SBP
        sum={sum}
        markup={markup}
        setSum={setSum}
        setErrorSum={setErrorSum}
        setMarkup={setMarkup}
        mask={maskPhone}
        phone={phone}
        setPhone={setPhone}
        activeBank={bank}
        setActiveBank={setBank}
        balance={purse?.balance}
        commission={typeof purse?.bank_commission === 'string' ? parseFloat(purse?.bank_commission) : 0}
      />
    },
    {
      name: 'Банковская карта',
      hidden: !showBankTabs,
      component: <PhoneAndCard
        title={'Номер карты'}
        mask={maskCard}
        sum={sum}
        markup={markup}
        setSum={setSum}
        setErrorSum={setErrorSum}
        setMarkup={setMarkup}
        value={cardNum}
        setValue={setCardNum}
        balance={purse?.balance}
        commission={typeof purse?.bank_commission === 'string' ? parseFloat(purse?.bank_commission) : 0}
        error={error}
      />
    },
    {
      name: 'Телефон',
      hidden: true,
      component: <PhoneAndCard
        title={'Номер телефона получателя'}
        mask={maskPhone}
        sum={sum}
        markup={markup}
        setSum={setSum}
        setErrorSum={setErrorSum}
        setMarkup={setMarkup}
        value={phone}
        setValue={setPhone}
        error={error}
        balance={purse?.balance}
        commission={typeof purse?.phone_commission === 'string' ? parseFloat(purse?.phone_commission) : 0}
      />
    },
  ];

  const click = () => {
    if (!enabledButton) {
      return;
    }
    if (!transactionReady) {
      setTransactionReady(true);
      setCheckbox(false);
    } else {
      if (purse?.id) {
        dispatch(createPayment({
          purse_id: purse?.id,
          sum: parseInt(sum),
          commission: parseInt(markup),
          card: cardNum ? cardNum.replaceAll(' ', '') : undefined,
          phone: phone ? phone : undefined,
          type: cardNum ? 'card' : 'phone',
        }));
      }
    }
  };

  const cancel = () => {
    setTransactionReady(false);
  };

  const changeTab = (index: number) =>{
    setTransactionReady(false);
    setPhone('');
    setCardNum('');
    setBank('');
    setSum('');
    setMarkup('');
    setCheckbox(false);
    setActiveTab(index);
    dispatch(clearError());
  };

  useEffect(() => {
    if (error.includes('Неверный номер')) {
      setTransactionReady(false);
    } else if (error.length) {
      dispatch(showPopup('moneyTransferReject'));
    } else {
      dispatch(hidePopup('moneyTransferReject'));
    }
  }, [error]);

  useEffect(() => {
    if (data.code === 'ok') {
      history.push('/dashboard/prizes');
      dispatch(showPopup('moneyTransferSuccess'));
      dispatch(clearData());
    }
  }, [data]);

  useEffect(() => {
    dispatch(clearError());
  }, [phone, cardNum]);

  useEffect(() => {
    setActiveTabName(tabs[activeTab].name);
  }, [activeTab]);

  useEffect(() => {
    if (activeTabName === 'Телефон') {
      dispatch(showPopup('moneyTransferAlert'));
    }
  }, [activeTabName]);

  useEffect(() => {
    dispatch(clearError());
  }, []);

  return (
    <div className={'money-transfer-tabs'}>
      <div className={'money-transfer-tabs__tab-list'}>
        {tabs.map((item, index) => {
          const isActive = activeTab === index;

          if (item.hidden) {
            return;
          }

          return (
            <div
              key={item.name}
              onClick={() => changeTab(index)}
              className={`
                money-transfer-tabs__tab-item
                ${isActive ? 'money-transfer-tabs__tab-item_active' : null}
              `}>
              {item.name}
            </div>
          );
        })}
      </div>
      <div className={'money-transfer-tabs__tab-content'}>
        {!transactionReady
          ? tabs[activeTab].component
          : (
            <>
              <div className={'money-transfer-tabs__ready-transaction'}>
                <ul className={'money-transfer-tabs__ready-transaction-list'}>
                  <li>
                    Номер {phone ? 'телефона' : 'карты'}:
                    <span className={'money-transfer-tabs__transaction-info'}> {phone ? phone : cardNum}</span>
                  </li>
                  <li>
                    Сумма перевода: <span className={'money-transfer-tabs__transaction-info'}>{sum}</span>
                  </li>
                  {bank ? (
                    <li>
                      Банк получателя: <span className={'money-transfer-tabs__transaction-info'}>{bank}</span>
                    </li>
                  ) : null}
                  <li>
                    Комиссия: <span className={'money-transfer-tabs__transaction-info'}>{markup} ₽</span>
                  </li>
                </ul>
              </div>
              <div className={'money-transfer-tabs__transaction-sum'}>
                Сумма списания с баланса: <b>{parseInt(sum) + markup} ₽</b>
              </div>
            </>
          )}
      </div>
      <div className={'money-transfer-tabs__checkbox'}>
        {transactionReady ? (
          <Checkbox checkbox={checkbox} onClick={() => setCheckbox(!checkbox)} />
        ) : null}
        <div className={'money-transfer-tabs__button-info'}>
          Продолжая ваши действия, вы подтверждаете корректность введенных данных и соглашаетесь с условиями документа
          <span
            onClick={() => {
              window.open('/Оферта физические лица.pdf', '_blank')?.focus();
            }}
            className={'money-transfer-tabs__link'}>
            &nbsp;Оферта для физических лиц
          </span>
        </div>
      </div>

      <Button
        loading={loading}
        loaderColor={'#4661C4'}
        disabled={!activeTab ? (!enabledButton && !bank.length) : !enabledButton}
        onClick={click}
        className={`money-transfer-tabs${transactionReady ? '__button-transaction-ready' : '__button-transaction'}`}>
        Отправить
      </Button>
      {
        transactionReady
          ? (
            <div onClick={cancel} className={'money-transfer-tabs__cancel-transaction'}>
              Изменить данные
            </div>
          ) : null
      }
    </div>
  );
};

export default MoneyTransferTabs;
