import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector} from 'utils/hooks';
import {getPaymentHistory} from 'store/components/MoneyTransferSlice';

import Title from 'components/title/Title';
import WindowLoading from 'components/windowLoading/WindowLoading';

import EmptyBasket from './assets/components/emptyBasket/EmptyBasket';
import InnerTabs from 'components/innerTabs/InnerTabs';
import PaymentsHistory from './assets/components/paymentsHistory/PaymentsHistory';
import PullBasket from './assets/components/pullBasket/PullBasket';

import './History.scss';

const History = () => {
  const historyOrders = useAppSelector(state => state.dashboard.historyOrders);
  const transactionsData = useAppSelector(state => state.payments.dataHistory);
  const dispatch = useDispatch();

  if (!historyOrders) {
    return null;
  }

  const [active, setActive] = useState(0);

  const tabBar = [
    {name: 'История заказов', child: historyOrders.length > 0 ? <PullBasket /> : <EmptyBasket />},
    {
      name: 'История платежей',
      child: <PaymentsHistory />,
      hidden: transactionsData.length === 0
    },
  ];

  useEffect(() => {
    dispatch(getPaymentHistory());
  }, []);

  return (
    <div className={'history'}>
      <Title>История заказов</Title>
      <WindowLoading id={'history'} className={'history__loading'} />
      <InnerTabs changeActiveTab={setActive} tabs={tabBar} activeTab={active} />
    </div>
  );
};

export default History;
