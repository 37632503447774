import React, {useState} from 'react';

import Title from 'components/title/Title';
import InnerTabs from 'components/innerTabs/InnerTabs';
import ActivationHistory from '../activationHistory/ActivationHistory';
import ProfileInfo from './assets/components/profileInfo/ProfileInfo';

const Profile = () => {
  const [active, setActive] = useState(0);

  const tabBar = [
    {name: 'Личные данные', child: <ProfileInfo />},
    {name: 'История активаций', child: <ActivationHistory />},
  ];

  const changeActiveTab = (index: number) => {
    setActive(index);
  };

  return (
    <div>
      <Title>Личный кабинет</Title>
      <InnerTabs changeActiveTab={changeActiveTab} tabs={tabBar} activeTab={active}/>
    </div>
  );
};

export default Profile;
