import React, {useEffect, useState} from 'react';


import Popup from 'components/popup/Popup';

import like from './assets/images/like.svg';

import './MoneyTransferSuccess.scss';


const MoneyTransferSuccess = () => {
  return (
    <Popup id={'moneyTransferSuccess'} className={'money-transfer-success'}>
      <div className={'money-transfer-success__container'}>
        <img src={like} alt="like" className={'money-transfer-success__icon'} />
        <h2 className={'money-transfer-success__title'}>Платеж успешно создан</h2>
      </div>
    </Popup>
  );
};

export default MoneyTransferSuccess;
