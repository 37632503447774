import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {getSearchForOtherProject, useAppDispatch, useAppSelector} from 'utils/hooks';
import {queryParsing} from 'utils/queryParse';

import {IStore} from 'store/store';
import {showPopup} from 'store/components/PopupSlice';

import {StorageItemsNames} from 'enums';

import Rules from 'components/Rules/Rules';
import Popup from 'components/popup/Popup';
import PhoneForm from './phoneForm/PhoneForm';
import CodeForm from './codeForm/CodeForm';

import './Auth.scss';

const Auth = () => {
  const dispatch = useAppDispatch();
  const {search} = useLocation();

  const cardNumberInStorage = localStorage.getItem(StorageItemsNames.CARD_NUMBER);

  const authtorized = useAppSelector(state => state.auth.authorized);

  const [phone, setPhone] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<string>(cardNumberInStorage ? cardNumberInStorage : '');
  const [showCodeForm, setShowCodeForm] = useState<any>(false);
  const [showPhoneInput, setShowPhoneInput] = useState<boolean>(false);
  const [showPopupWithOtherProject, setShowPopupWithOtherProject] = useState<boolean>(false);
  const [rememberCardNumber, setRememberCardNumber] = useState<boolean>(false);

  const visiblePopup = useSelector((state: IStore) => state.popup.popup);
  const propsPopup = useSelector((state: IStore) => state.popup.props);

  useEffect(() => {
    setPhone('');
    setShowCodeForm(false);
    setRememberCardNumber(false);
  }, [visiblePopup['auth']]);

  useEffect(() => {
    getSearchForOtherProject(
      search,
      setShowPopupWithOtherProject,
      dispatch,
      'auth',
    );
  }, []);

  useEffect(() => {
    const queryObj = queryParsing();

    if (!queryObj) {
      return;
    }

    if (queryObj['card']) {
      dispatch(showPopup('auth'));
      setShowPhoneInput(true);
      localStorage.setItem(StorageItemsNames.CARD_NUMBER, queryObj['card']);
    } else if (queryObj['is_redirect']) {
      dispatch(showPopup('auth'));
      setShowPhoneInput(false);
    }

    if (queryObj['phone']){
      setPhone(`+${queryObj['phone']}`);
      setTimeout(() => setPhone(`+${queryObj['phone']}`), 0);
    }
  }, []);

  const onClose = () => {
    propsPopup.onClose && propsPopup.onClose();
  };

  return (
    <Popup
      onClose={onClose}
      id={'auth'}
      className={`
        auth 
        ${showPopupWithOtherProject && 'auth_popup-with-other-project'}
      `}>
      <div className={'auth__container'}>
        {
          showCodeForm
            ? (
              <CodeForm
                phone={phone}
                setPhone={setPhone}
                setShowCodeForm={setShowCodeForm}
                rememberCardNumber={rememberCardNumber}
              />
            ) : (
              <PhoneForm
                showPhoneInput={showPhoneInput}
                setShowPhoneInput={setShowPhoneInput}
                rememberCardNumber={rememberCardNumber}
                setRememberCardNumber={setRememberCardNumber}
                phone={phone}
                setPhone={setPhone}
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                setShowCodeForm={setShowCodeForm}
              />
            )
        }
        {
          !showCodeForm
            ? <Rules />
            : null
        }
      </div>
    </Popup>
  );
};

export default Auth;
