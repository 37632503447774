import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {setLoading, setSearchFilter} from 'store/components/PrizesSlice';
import {IPurse} from 'store/components/AuthSlice';
import {setPurse} from 'store/components/DashboardSlice';
import {getCart} from 'store/components/CartSlice';

import WindowLoading from 'components/windowLoading/WindowLoading';
import MoneyTransferButton from '../moneyTransferButton/MoneyTransferButton';

const PurseList = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [choosePurseText, setChoosePurseText] = useState('');

  const purse = useAppSelector((state) => state.dashboard.purse);
  const showMoneyTransfer = useAppSelector((state) => state.dashboard.showMoneyTransfer);
  const user = useAppSelector((state) => state.auth.user);
  const pursesLoading = useAppSelector((state) => state.prizes.loading.pursesLoading);

  const namesPurse = [
    'Первый',
    'Второй',
    'Третий',
    'Четвёртый',
    'Пятый',
    'Шестой',
    'Седьмой',
    'Восьмой',
    'Девятый',
    'Десятый',
    'Одиннадцатый',
    'Двенадцатый',
    'Тринадцатый',
    'Четырнадцатый',
    'Пятнадцатый',
    'Шестнадцатый',
    'Семнадцатый',
    'Восемнадцатый',
    'Девятнадцатый',
    'Двадцатый',
    'Двадцать Первый',
    'Двадцать Второй',
    'Двадцать Третий',
    'Двадцать Четвёртый',
    'Двадцать Пятый',
    'Двадцать Шестой',
    'Двадцать Седьмой',
    'Двадцать Восьмой',
    'Двадцать Девятый',
    'Тридцатый',
    'Тридцать Первый',
    'Тридцать Второй',
    'Тридцать Третий',
    'Тридцать Четвёртый',
    'Тридцать Пятый',
    'Тридцать Шестой',
    'Тридцать Седьмой',
    'Тридцать Восьмой',
    'Тридцать Девятый',
    'Сороковой',
    'Сорок Первый',
    'Сорок Второй',
    'Сорок Третий',
    'Сорок Четвёртый',
    'Сорок Пятый',
    'Сорок Шестой',
    'Сорок Седьмой',
    'Сорок Восьмой',
    'Сорок Девятый',
    'Пятидесятый',
    'Пятидесятый Первый',
    'Пятидесятый Второй',
    'Пятидесятый Третий',
    'Пятидесятый Четвёртый',
    'Пятидесятый Пятый',
    'Пятидесятый Шестой',
    'Пятидесятый Седьмой',
    'Пятидесятый Восьмой',
    'Пятидесятый Девятый',
    'Шестидесятый',
    'Шестидесятый Первый',
    'Шестидесятый Второй',
    'Шестидесятый Третий',
    'Шестидесятый Четвёртый',
    'Шестидесятый Пятый',
    'Шестидесятый Шестой',
    'Шестидесятый Седьмой',
    'Шестидесятый Восьмой',
    'Шестидесятый Девятый',
    'Семидесятый',
    'Семидесятый Первый',
    'Семидесятый Второй',
    'Семидесятый Третий',
    'Семидесятый Четвёртый',
    'Семидесятый Пятый',
    'Семидесятый Шестой',
    'Семидесятый Седьмой',
    'Семидесятый Восьмой',
    'Семидесятый Девятый',
    'Восьмидесятый',
    'Восьмидесятый Первый',
    'Восьмидесятый Второй',
    'Восьмидесятый Третий',
    'Восьмидесятый Четвёртый',
    'Восьмидесятый Пятый',
    'Восьмидесятый Шестой',
    'Восьмидесятый Седьмой',
    'Восьмидесятый Восьмой',
    'Восьмидесятый Девятый',
    'Девяностый',
    'Девяностый Первый',
    'Девяностый Второй',
    'Девяностый Третий',
    'Девяностый Четвёртый',
    'Девяностый Пятый',
    'Девяностый Шестой',
    'Девяностый Седьмой',
    'Девяностый Восьмой',
    'Девяностый Девятый',
    'Сотый'
  ];

  const changePurse = async (purseInner: IPurse) => {
    if (purse?.id !== purseInner.id) {
      await dispatch(setLoading({name: 'prizes', value: true}));
      await dispatch(setLoading({name: 'pursesLoading', value: true}));
      await dispatch(setPurse(purseInner));
      await dispatch(setSearchFilter(''));
      await dispatch(getCart());
    }
  };

  const activePurser = (id: number) => {
    return (purse && purse.id) === id
      ? 'header-prizes__purse header-prizes__purse_active'
      : ' header-prizes__purse';
  };

  useEffect(() => {
    if (!user?.purses.length){
      return;
    }
    if (user?.purses.length > 1){
      setChoosePurseText('Выберите любой кошелек с которого будут списаны баллы');
    } else {
      setChoosePurseText('');
    }
  },[user?.purses.length]);

  return (
    <div>
      {
        user?.purses.filter(item => item.balance !== 0).length ? (
          <p className={'header-prizes__subtitle'}>
            {choosePurseText}
          </p>
        ) : null
      }
      <div className={'header-prizes__content'}>
        <ul className={'header-prizes__list-purse'}>
          {
            user?.purses
              .map((purseInner: IPurse, index: number) => {
                return (
                  <li
                    className={activePurser(purseInner.id)}
                    key={purseInner.id}>
                    {!pursesLoading ? (
                      <div
                        onClick={() => !pursesLoading && changePurse(purseInner)}>
                        <span className={'header-prizes__name-purse'}>
                          {namesPurse[index]} кошелек
                        </span>
                        <span className={'header-prizes__balance'}>
                          {purseInner.balance} ₽
                        </span>
                      </div>
                    ) : (
                      <WindowLoading size={39} id={'pursesLoading'} className={'header-prizes__purse-loading'}/>
                    )}
                  </li>
                );
              })
          }
        </ul>
        {
          showMoneyTransfer ? (<MoneyTransferButton />) : null
        }

      </div>
    </div>
  );
};

export default PurseList;
