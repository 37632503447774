import {ISettingItem} from '../components/SettingSlice';

export const Colors: {[key: string]: ISettingItem} = {
  'mtsfirst' : {
    logoIconColor: '#fff',
    infoIcon: '#007CFF',
    linkIcon: '#007CFF',
    firstClipBoardColor: '#000',
    secondClipBoardColor: '#000',
    menuIconColor: '#969FA8',
    menuIconOpacity: '0.3',
    enterIconColor: '#fff',
    firstColorIconIdle: '#969FA8',
    firstColorIconActive: '#ff0032',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#ff0032',
    loadButtonColor: '#000',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#000',
    cartIconOpacity: '1',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#ff0032',
      activeSecondColor: '#969FA8',
      opacity: '1'
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1',
    },
    crossIcon: {
      mainIcon: '#ff0032',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'makfa' : {
    logoIconColor: '#fff',
    infoIcon: '#007934',
    linkIcon: '#2F80ED',
    firstClipBoardColor: '#E30613',
    secondClipBoardColor: '#E30613',
    menuIconColor: '#E30613',
    enterIconColor: '#E30613',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#969FA8',
    firstColorIconActive: '#E30613',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#E30613',
    loadButtonColor: '#E30613',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#E30613',
    cartIconOpacity: '1',
    letter: {
      verified: '#007934',
      noVerified: '#ED1C24',
    },
    tabMenu: {
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#E30613',
      activeSecondColor: '#969FA8',
      opacity: '1'
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#E30613',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'smeg' : {
    logoIconColor: '#fff',
    infoIcon: '#007934',
    linkIcon: '#2F80ED',
    firstClipBoardColor: '#E30613',
    secondClipBoardColor: '#E30613',
    menuIconColor: '#E30613',
    enterIconColor: '#E30613',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#969FA8',
    firstColorIconActive: '#E30613',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#E30613',
    loadButtonColor: '#E30613',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#E30613',
    cartIconOpacity: '1',
    letter: {
      verified: '#007934',
      noVerified: '#ED1C24',
    },
    tabMenu: {
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#E30613',
      activeSecondColor: '#969FA8',
      opacity: '1'
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#E30613',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'local' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  default : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  aclub : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#EF3124',
    firstClipBoardColor: '#EF3124',
    secondClipBoardColor: '#505759',
    menuIconColor: '#505759',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#505759',
    loadButtonColor: '#505759',
    firstColorIconActive: '#EF3124',
    secondColorIconIdle: '#505759',
    secondColorIconActive: '#EF3124',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#505759',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#505759',
      secondColor: '#505759',
      activeMainColor: '#EF3124',
      activeSecondColor: '#EF3124',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#505759',
      secondIcon: '#505759',
      opacity: '0.3',
    },
  },
  'womens-day' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'sargazarm' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'asgbonus': {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'proektirovanie' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  geo : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  loto: {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'loto-pr': {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#2F41B0',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#2F41B0',
    menuIconColor: '#2F41B0',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#2F41B0',
    loadButtonColor: '#2F41B0',
    firstColorIconActive: '#2F41B0',
    secondColorIconIdle: '#2F41B0',
    secondColorIconActive: '#2F41B0',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2F41B0',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#2F41B0',
      activeSecondColor: '#2F41B0',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#2F41B0',
      secondIcon: '#2F41B0',
      opacity: '0.3',
    },
  },
  'birthday': {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#fe4608',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#fe4608',
    menuIconColor: '#fe4608',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#000',
    loadButtonColor: '#fe4608',
    firstColorIconActive: '#fe4608',
    secondColorIconIdle: '#000',
    secondColorIconActive: '#fe4608',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#fe4608',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      mainColor: '#000',
      secondColor: '#000',
      activeMainColor: '#fe4608',
      activeSecondColor: '#fe4608',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#fe4608',
      secondIcon: '#fe4608',
      opacity: '0.3',
    },
  },
  'raiffeisen': {
    logoIconColor: '#2B2D33',
    infoIcon: '#479A78',
    linkIcon: '#2B2D33',
    firstClipBoardColor: '#2B2D33',
    secondClipBoardColor: '#2B2D33',
    menuIconColor: '#2B2D33',
    enterIconColor: '#2B2D33',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#2B2D33',
    loadButtonColor: '#2B2D33',
    firstColorIconActive: '#2B2D33',
    secondColorIconIdle: '#2B2D33',
    secondColorIconActive: '#2B2D33',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2B2D33',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#479A78',
      noVerified: '#CD5A50',
    },
    tabMenu: {
      mainColor: '#2B2D33',
      secondColor: '#2B2D33',
      activeMainColor: '#2B2D33',
      activeSecondColor: '#2B2D33',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1'
    },
    crossIcon: {
      mainIcon: '#2B2D33',
      secondIcon: '#2B2D33',
      opacity: '0.3',
    },
  },
  'brucite': {
    logoIconColor: '#5AC3BE',
    infoIcon: '#5AC3BE',
    linkIcon: '#5AC3BE',
    firstClipBoardColor: '#5AC3BE',
    secondClipBoardColor: '#969FA8',
    menuIconColor: '#5AC3BE',
    enterIconColor: '#5AC3BE',
    menuIconOpacity: '0.3',
    firstColorIconIdle: '#969FA8',
    loadButtonColor: '#2B2D33',
    firstColorIconActive: '#5AC3BE',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#5AC3BE',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2B2D33',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#479A78',
      noVerified: '#CD5A50',
    },
    tabMenu: {
      mainColor: '#5AC3BE',
      secondColor: '#969FA8',
      activeMainColor: '#5AC3BE',
      activeSecondColor: '#969FA8',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1'
    },
    crossIcon: {
      mainIcon: '#5AC3BE',
      secondIcon: '#969FA8',
      opacity: '0.3',
    },
  },
};
