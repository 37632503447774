import React from 'react';
import {useHistory} from 'react-router-dom';

import {GetIcon} from 'store/enums/normalizedIcons';
import Button from 'components/button/Button';

import './EmptyBasket.scss';

const EmptyBasket = () => {
  const history = useHistory();

  const goToPrizes = () => {
    history.push('prizes');
  };

  return (
    <div className={'empty-basket'}>
      <p className={'empty-basket__info'}>У вас нет заказов!</p>
      <p className={'empty-basket__prompt'}>Выберите подарочный сертификат себе или в подарок.</p>
      <Button
        icon={<GetIcon iconName={'Tile'} />}
        className={'empty-basket__button'}
        onClick={goToPrizes}>
        Выбрать сертификат
      </Button>
    </div>
  );
};

export default EmptyBasket;
