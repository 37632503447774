import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useAppSelector} from 'utils/hooks';

import MoneyTransferTabs from './assets/components/MoneyTransferTabs/MoneyTransferTabs';
import Title from 'components/title/Title';
import GoBack from 'components/goBackButton/GoBack';

import './MoneyTransfer.scss';

const MoneyTransfer = () => {
  const purse = useAppSelector(state => state.dashboard.purse);
  const showMoneyTransfer = useAppSelector(state => state.dashboard.showMoneyTransfer);
  const history = useHistory();
  const firstTab = purse?.bank_commission ? 1 : 2;
  const [transactionReady, setTransactionReady] = useState(false);
  const [activeTab, setActiveTab] = useState(firstTab);
  const [info, setInfo] = useState({title: '', subtitle: '', data: [''], buttonInfo: ''});

  const goBackButton = () => {
    if (transactionReady) {
      setTransactionReady(false);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (activeTab === 1) {
      setInfo({
        title: 'Переводы в другие банки по номеру карты!',
        subtitle: '',
        data: [
          'Ввести номер карты‚ на который будет произведена оплата',
          'Ввести сумму перевода.',
          'Максимальная сумма единоразового платежа не более 14 500 руб.',
          'Минимальная комиссия 50 руб.',
        ],
        buttonInfo: 'банковской карте'
      });
    }
    if (activeTab === 2) {
      setInfo({
        title: 'Перевод по номеру телефона!',
        subtitle: '',
        data: [
          'Ввести номер телефона‚ на который будет произведена оплата (без 8 или +7)',
          'Ввести сумму перевода.',
          'Максимальная сумма платежа 5 000 руб.',
          'Минимальная комиссия 50 руб.',
        ],
        buttonInfo: 'номеру телефона'
      });
    }
    if (activeTab === 0) {
      setInfo({
        title: 'Переводы в другие банки по номеру телефона!',
        subtitle: 'Не важно какой банк, главное, чтобы он был подключен к СБП.',
        data: [
          'Ввести номер телефона‚ на который будет произведена оплата (без 8 или +7)',
          'Ввести сумму перевода.',
          'Выбрать банк получателя.',
          'Проверить ФИО получателя',
        ],
        buttonInfo: 'СБП'
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (!showMoneyTransfer) {
      history.push('/dashboard/prizes');
    }
  }, []);

  return (
    <div className={'money-transfer'}>
      <GoBack onClick={goBackButton} />
      <Title>
        Перевод баллов на банковскую карту
      </Title>
      <div className={'money-transfer__balance'}>
        Доступна сумма&nbsp;
        <span className={'money-transfer__sum'}>
          {purse?.balance} ₽
        </span>
      </div>
      <div className={'money-transfer__container'}>
        <div className={'money-transfer__transfer-block'}>
          <MoneyTransferTabs
            transactionReady={transactionReady}
            setTransactionReady={setTransactionReady}
            activeTab={activeTab}
            buttonInfo={info.buttonInfo}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className={'money-transfer__info'}>
          <b className={'money-transfer__info-title'}>
            {info?.title}
            <br/>
            {info?.subtitle ? info.subtitle : ''}
          </b>
          <ul>
            {info.data.map((item, index) => {
              return (<li key={index}>{item}</li>);
            })}
          </ul>
          Зачисление денежных средств происходит единоразово, полностью в течение 24-х часов.
        </div>
      </div>
    </div>
  );
};

export default MoneyTransfer;
