import React from 'react';
import {
  Logo,
  Burger,
  Tile,
  Cart,
  Help,
  User,
  Card,
  LogOutIcon,
  CubeMenu,
  BurgerMainMenu,
  CartAuthMenu,
  BurgerMini,
  SettingIcon,
  LoadButton,
  InfoIcon,
  MiniLogoWhite,
  UpwardIcon,
  ArrowLink,
  ClipBoard,
  StatusCard,
  Cross,
  LetterVerified,
  LetterNoVerified,
  SelectStepNominal,
  Pencil,
  RaifUser,
  RaifCard,
  RaifCart,
  RaifSettingIcon,
  RaifCartAuthMenu,
  RaifHelp,
  RaifHistory,
  RaifTile,
  RaifLogOutIcon,
  RaifBurgerMainMenu,
  RaifLetterVerified,
  RaifCross,
  Check,
  RaifCheck,
  InCart,
  RaifInCart,
  ArrowLeft,
} from 'assets/images/IconPack';
import {store} from '../store';

export const appIcons = {
  default: {
    CartAuthMenu: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <CartAuthMenu
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ArrowLeft: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <ArrowLeft
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    BurgerMainMenu: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMainMenu
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    History: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMainMenu
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    MiniLogoWhite: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <MiniLogoWhite
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    User: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <User
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Logo: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Logo
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LoadButton: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <LoadButton
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Burger: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Burger
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Tile: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Tile
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cart: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Cart
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Help: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Help
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Card: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Card
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LogOutIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <LogOutIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    CubeMenu: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <CubeMenu
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    BurgerMini: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMini
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    SettingIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <SettingIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InfoIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff', width}) {
      return <InfoIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
        width={width}
      />;
    },
    UpwardIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <UpwardIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ArrowLink: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <ArrowLink
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ClipBoard: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <ClipBoard
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    StatusCard: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <StatusCard
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cross: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Cross
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Check: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Check
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InCart: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <InCart
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LetterVerified: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <LetterVerified
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LetterNoVerified: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <LetterNoVerified
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },

    SelectStepNominal: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <SelectStepNominal
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Pencil: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Pencil
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
  },
  raiffeisen: {
    CartAuthMenu: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCartAuthMenu
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    BurgerMainMenu: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifBurgerMainMenu
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    History: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifHistory
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    MiniLogoWhite: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <MiniLogoWhite
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    User: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifUser
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Logo: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Logo
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LoadButton: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <LoadButton
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Burger: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Burger
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Tile: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifTile
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cart: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCart
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Help: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifHelp
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Card: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCard
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LogOutIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifLogOutIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    CubeMenu: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <CubeMenu
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    BurgerMini: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <BurgerMini
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    SettingIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifSettingIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InfoIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff', width}) {
      return <InfoIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
        width={width}
      />;
    },
    UpwardIcon: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <UpwardIcon
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ArrowLink: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <ArrowLink
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    ClipBoard: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <ClipBoard
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    StatusCard: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <StatusCard
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Cross: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCross
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Check: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifCheck
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    InCart: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifInCart
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LetterVerified: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <RaifLetterVerified
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    LetterNoVerified: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <LetterNoVerified
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    SelectStepNominal: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <SelectStepNominal
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
    Pencil: function Icon ({opacity = '#fff', fill = '#fff', secondaryFill = '#fff'}) {
      return <Pencil
        opacity={opacity}
        fill={fill}
        secondaryFill={secondaryFill}
      />;
    },
  }
};

Object.freeze(appIcons);

export const GetIcon = ({
  opacity = '0.3',
  fill = '#fff',
  secondaryFill = '#fff',
  width = '16',
  iconName
}) => {
  const currentDomain = store.getState().settings.currentDomainName;
  if (appIcons[currentDomain] && appIcons[currentDomain][iconName]) {
    return appIcons[currentDomain][iconName]({opacity, fill, secondaryFill, width});
  } else {
    return appIcons.default[iconName]({opacity, fill, secondaryFill, width});
  }
};
