import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {sendCode} from 'store/components/AuthSlice';
import {hideAllExcept, hidePopup, showPopup} from 'store/components/PopupSlice';

import Input from 'components/input/Input';
import Button from 'components/button/Button';

import Timer from './assets/components/timer/Timer';

import './CodeForm.scss';

interface ICodeForm {
  phone: string;
  setShowCodeForm: any;
  setPhone: any;
  rememberCardNumber: boolean;
  acceptRules?: boolean;
}

const CodeForm: React.FC<ICodeForm> = ({
  phone,
  setShowCodeForm,
  setPhone,
  rememberCardNumber,
  acceptRules = true,
}) => {
  const dispatch = useAppDispatch();

  const test = localStorage.getItem('tester');
  const allowAuth = useAppSelector(state => state.settings.allowAuth);

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [sendButton, setSendButton] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);

  const closeCodeForm = () => {
    setPhone('');
    setCode('');
    setError('');
    setShowCodeForm(false);
  };

  const showRegisterPopup = () => {
    closeCodeForm();
    dispatch(hidePopup('auth'));
    dispatch(showPopup('register'));
  };

  const sendCodeForm = () => {
    if (!allowAuth && (test !== 'true')) {
      dispatch(hideAllExcept('dontAllowAuth'));
      return;
    }

    setLoading(true);
    if (!loading) {
      dispatch(sendCode({
        phone,
        code,
        showRegisterPopup,
        setError,
        rememberCardNumber,
      })).then(() => {
        history.pushState(null, '', '/');
        setTimeout(() => setLoading(false), 500);
        setTimeout(() => setError(''), 3000);
      });
    }
  };

  useEffect( () => {
    if (code.replace('_', '').length === 4) {
      setSendButton(true);
    } else {
      setSendButton(false);
    }
  }, [code]);

  const onKeyPressHandler = (event: any) => {
    if (event.keyCode === 13) {
      sendButton && sendCodeForm();
    }
  };

  return (
    <div className={'code-form'} onKeyUp={onKeyPressHandler}>
      <h3 className={'code-form__title'}>
        Проверка телефона
      </h3>
      <Input
        setValue={setCode}
        value={code}
        marginBottom={20}
        error={error}
        setError={setError}
        mask={[/\d/, /\d/, /\d/, /\d/]}
        paddingBottom={10}
        placeholder={'Код - 4 цифры'}
      />
      <p className={'code-form__number'}>
        Введите последние 4 цифры с входящего звонка, который поступит в течение минуты на <span className={'code-form__number_non-wrap'}>{phone}</span>
      </p>
      <Button
        disabled={!sendButton && !acceptRules}
        width={'100%'}
        marginBottom={20}
        loading={loading}
        onClick={sendCodeForm}>
        Проверить код и войти
      </Button>
      <Timer phone={phone} />
      <a href="#" onClick={closeCodeForm} className={'code-form__change-number'}>Изменить номер</a>
    </div>
  );
};

export default CodeForm;
