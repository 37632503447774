import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {useAppSelector} from 'utils/hooks';

import {IStore} from 'store/store';
import {showPopup} from 'store/components/PopupSlice';
import {setVisibleMenu} from 'store/components/DashboardSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import AuthorizedMenu from './assets/components/authorizedMenu/AuthorizedMenu';
import ButtonContainer from './assets/components/buttonContainer/ButtonContainer';

import logo from './assets/images/logo.svg';
import AClubLogo from './assets/images/AClubLogo.svg';
import logoLotoPr from './assets/images/logoLotoPr.svg';
import raifLogo from './assets/images/raif-logo.svg';
import logoMakfa from './assets/images/logoMakfa.svg';
import smakLogo from './assets/images/smakLogo.svg';

import './Header.scss';

interface IHeader {
  marginBottom?: string | number;
  setShowMobileButton?: (A: boolean) => any;
  showMobileButton?: boolean;
}

const Header: React.FC<IHeader> = ({marginBottom, showMobileButton, setShowMobileButton}) => {
  const w = window;
  const href = w.location.href;

  const dispatch = useDispatch();
  const history = useHistory();

  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [headerTop, setHeaderTop] = useState('show');

  const headerRef = useRef<HTMLElement>();

  const colors = useSelector((state: IStore) => state.settings.colors);
  const domain = useSelector((state: IStore) => state.settings.currentDomainName);
  const authorized = useSelector((state: IStore) => state.auth.authorized);
  const currentDomainName = useAppSelector((state) => state.settings.currentDomainName);

  const openMainPage = () => {
    authorized ? history.push('prizes') : history.push('/');
  };

  const logos: {[key: string]: any} = {
    'makfa': logoMakfa,
    'smeg': smakLogo,
    'default': logo,
    'birthday': logo,
    'local': logo,
    'geo': logo,
    'brucite': logo,
    'raiffeisen': raifLogo,
    'sargazarm': logo,
    'proektirovanie': logo,
    'aclub': AClubLogo,
    'loto-pr': logoLotoPr,
  };

  const openJivo = () => {
    // @ts-ignore
    jivo_api.open();
    return false;
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = w.pageYOffset;
      if (prevScrollPosition > position || position < 30) {
        setHeaderTop('show');
      } else if (prevScrollPosition < position){
        setHeaderTop('hide');
      }
      if (headerRef.current)
        if (position > 15) {
          headerRef.current.style.backgroundColor = authorized ? 'rgba(255, 255, 255)' : 'rgba(255, 251, 248, 0.96)';
          headerRef.current.style.boxShadow = authorized ? '' : 'rgb(226 226 226) 0px 1px 20px -6px';
          if (position > 30 && setShowMobileButton) {
            setShowMobileButton(true);
          }
        } else {
          setShowMobileButton && setShowMobileButton(false);
          headerRef.current.style.backgroundColor = 'rgba(255, 255, 255, 0)';
        }
      setPrevScrollPosition(position);
    };
    w.addEventListener('scroll', handleScroll);

    return () => {
      w.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  useEffect(() => {
    w.scrollTo(0, 0);
  }, [history.location]);

  const inlineStyleHeader = {
    marginBottom: marginBottom,
    backgroundColor: authorized ? '#fff' : 'transparent',
    zIndex: authorized ? 1 : 2,
  };

  const openAuthPopup = () => {
    dispatch(showPopup('auth'));
  };

  const openProfile = () => {
    history.push('profile');
  };

  const openCheckCard = () => {
    dispatch(showPopup('checkCard'));
  };

  const openMenu = () => {
    dispatch(setVisibleMenu(true));
  };

  return (
    // @ts-ignore
    <header ref={headerRef} className={'header'}  style={inlineStyleHeader}>
      <div
        className={[
          'header__container',
          authorized
            ? 'header__container_row-reverse'
            : 'header__container_row'
        ].join(' ')}>
        {
          authorized
            ? <AuthorizedMenu />
            : (
              <span className={'menu__burger'} onClick={openMenu}>
                <GetIcon
                  iconName={'BurgerMainMenu'}
                  fill={colors[domain].firstColorIconIdle}
                  secondaryFill={colors[domain].secondColorIconIdle}
                />
              </span>
            )
        }

        <div  className={'header__logo-container'}>
          <img
            src={logos[currentDomainName]}
            alt="logo"
            className={'header__logo header__logo_mobile'}
            onClick={openMainPage}
          />
        </div>
        <img
          src={logos[currentDomainName]}
          alt="logo"
          className={'header__logo header__logo_desktop'}
          onClick={openMainPage}
        />
        {
          authorized
            ? (
              <div onClick={openProfile} className={'header__link-icon'}>
                {
                  href.includes('profile')
                    ? (
                      <GetIcon
                        iconName={'User'}
                        fill={colors[domain].firstColorIconActive}
                        secondaryFill={colors[domain].secondColorIconActive}
                      />
                    )
                    : (
                      <GetIcon
                        iconName={'User'}
                        fill={colors[domain].firstColorIconIdle}
                        secondaryFill={colors[domain].secondColorIconIdle}
                      />
                    )
                }
              </div>
            ) : (
              <>
                <ButtonContainer openJivo={openJivo} />
                <div className={'header__link-group'}>
                  <div onClick={openCheckCard} className={'header__link-icon'}>
                    <GetIcon
                      iconName={'Card'}
                      fill={colors[domain].firstColorIconIdle}
                      secondaryFill={colors[domain].secondColorIconIdle}
                    />
                  </div>
                </div>
              </>
            )
        }
      </div>
    </header>
  );
};

export default Header;
