import React, {useEffect, useState} from 'react';


import {useAppSelector} from 'utils/hooks';
import Popup from 'components/popup/Popup';

import cross from './assets/images/cross.svg';

import './MoneyTransferReject.scss';

const MoneyTransferReject = () => {
  const error = useAppSelector(state => state.payments.error);

  return (
    <Popup id={'moneyTransferReject'} className={'money-transfer-reject'}>
      <div className={'money-transfer-reject__container'}>
        <img src={cross} alt="cross" className={'money-transfer-reject__icon'} />
        <h2 className={'money-transfer-reject__title'}>{error}</h2>
      </div>
    </Popup>
  );
};

export default MoneyTransferReject;
