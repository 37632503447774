import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

import ax from 'utils/ax';
import {StorageItemsNames} from 'enums';
import {hidePopup, showPopup} from './PopupSlice';
import {getUser, sendEmailVerified} from './ProfileSlice';
import {setPurse} from './DashboardSlice';
import {getPathURL, baseURL} from 'env/env.json';

export interface IPurse {
  id: number;
  balance: number;
  bank_commission: string | null;
  phone_commission: string | null;
}

export interface IUser {
  id: string;
  name: string;
  phone: string;
  email: string;
  purses: IPurse[];
  status: number;
  email_verified_at: string | null;
  created_at: string;
  updated_at: string;
  token: string;
  is_new_user: boolean;
  refresh_token?: string;
}

interface ResultIGetCode {
  data: {
    code?: string,
    status?: string,
    data: IGetCode
  }
}

interface ResultIGetPath {
  data: {
    data: {
      code?: string,
      status?: string,
      result: IGetPath
    }
  }
}

export interface IAuth {
  authorized: boolean;
  is_required_email: boolean;
  user: IUser | undefined;
  numberCard: string[] | [];
}

export interface IGetCode {
  message: string;
  user?: IUser;
  error_code?: string;
}

export interface IGetPath {
  url: string;
}

type GetCodeType = {
  phone: string,
  send_code?: string,
  captcha?: string;
}

export const getCode = createAsyncThunk(
  'auth/getCode',
  async ({phone, send_code, captcha}: GetCodeType) => {
    return await ax().post('/api/auth/step1', {phone, send_code, captcha})
      .then(({data}: ResultIGetCode) => {
        return data.data;
      });
  }
);

export const getPath = createAsyncThunk(
  'auth/getPath',
  async ({cardNumber}: {cardNumber: number}) => {
    return await ax().post(getPathURL, {cardNumber})
      .then((data: ResultIGetPath) => data);
  }
);

export const getNumberCard = createAsyncThunk(
  'auth/getNumberCard',
  async ({phone, token}: {phone: string; token: string}) => {
    return await ax().post(
      '/api/cards/get-activated-cards',
      {phone},
      {headers: {'Authorization': `Bearer ${token}`}})
      .then(({data}: any) => {
        return data.data.result.cards;
      });
  }
);

export const sendCode = createAsyncThunk(
  'auth/sendCode',
  async (payload: {
    phone: string;
    code: string;
    setError?: any;
    showRegisterPopup?: any;
    rememberCardNumber: boolean;
  }, {dispatch}) => {
    const response = await ax()
      .post('/api/auth/step2', {phone: payload.phone, code: payload.code});

    const {data} = response.data;

    const auth = async () => {
      dispatch(hidePopup('auth'));
      dispatch(hidePopup('checkNumberCard'));
      await localStorage.setItem(StorageItemsNames.USER, JSON.stringify(data.user));
      await localStorage.setItem(StorageItemsNames.TOKEN, data.user.token);
      await localStorage.setItem(StorageItemsNames.REFRESH_TOKEN, data.user.refresh_token);
      dispatch(setPurse(data.user.purses[0]));
      dispatch(authRedirect());
    };

    if (data.code === 'error') {
      payload.setError && payload.setError(data.result);
    } else {
      if (payload.rememberCardNumber) {
        dispatch(getNumberCard({phone: payload.phone, token: data.user.token}))
          .then(() => {
            dispatch(hidePopup('auth'));
            dispatch(showPopup({popup: 'checkNumberCard', props: {
              onClick: auth,
            }}));
          });
      } else if (data.user.is_new_user || !data.user.name) {
        payload.showRegisterPopup && payload.showRegisterPopup();
      } else {
        auth();
      }

      return data.user;
    }
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (payload: {
    email: string;
    name: string;
    surname: string;
    history: any;
    captcha: string;
    token: string;
    refreshToken: string;
  }, {getState, dispatch}) => {
    const {auth}: any = getState();
    let response;

    try {
      const {origin} = window.location;
      response = await axios.post(baseURL + 'api/user/register', {
        email: payload.email || '',
        name: payload.name,
        surname: payload.surname,
        phone: auth.user && auth.user.phone,
        captcha_response: payload.captcha,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${payload.token}`,
          'Referer': origin.replace('www.', ''),
        }
      });
    } catch (e: any) {
      if (e.response.status === 422) {
        response = e.response;
      }
    }

    const {data} = response.data;

    if (data.code !== 'error') {

      await localStorage.setItem(StorageItemsNames.TOKEN, payload.token);
      await localStorage.setItem(StorageItemsNames.REFRESH_TOKEN, payload.refreshToken);
      await dispatch(getUser()).then(() => dispatch(authRedirect()));
      await dispatch(hidePopup('register'));
      await payload.history.push('/dashboard/prizes');
      await dispatch(showPopup('activationCard'));
    }

    return data;
  }
);

export const authRedirect = createAsyncThunk(
  'auth/authRedirect',
  async () => {
    const userStorage = await JSON
      .parse(localStorage.getItem(StorageItemsNames.USER) || '{}');

    return userStorage;
  }
);

const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    authorized: false,
    user: undefined,
    is_required_email: !window.location.host.includes('pepsico'),
    numberCard: [],
  },
  reducers: {
    signInLaravel: () => {
      try {
        ax().get('/sanctum/csrf-cookie');
      } catch (e) {
        console.log(e);
      }
    },
    logOut: (state: IAuth) => {
      try {
        ax().post('api/auth/logout');
      } catch (e) {
        console.log(e);
      }

      const storageValues = Object.values(StorageItemsNames);

      storageValues.map((item) => {
        if (item !== StorageItemsNames.CARD_NUMBER) {
          localStorage.removeItem(item);
        }
      });

      state.authorized = false;
      state.user = undefined;
    },
    setUser: (state: IAuth, payload) => {
      state.user = payload.payload;
    },
    setAuthorized: (state: IAuth, payload) => {
      state.authorized = payload.payload;
    },
    // setBalanceCurrentPurse: (state: IAuth, payload) => {
    //   state.user?.purses[payload.payload.index] = payload.payload.value;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(sendCode.fulfilled, (state: IAuth, action) => {
      state.user = action.payload;
    });
    builder.addCase(getNumberCard.fulfilled, (state: IAuth, {payload}) => {
      state.numberCard = payload;
    });
    builder.addCase(signUp.fulfilled, (state: IAuth, {payload}) => {
      const user = payload.result[0];

      if (user && user.token) {
        state.user = user;
        localStorage.setItem(StorageItemsNames.USER, JSON.stringify(user));
      }
    });
    builder.addCase(authRedirect.fulfilled, (state: IAuth, action) => {
      state.user = action.payload;

      const userIsNull = state.user && Object.keys(state.user).length > 0;

      if (userIsNull) {
        state.authorized = true;
      } else {
        state.authorized = false;
      }
    });
  },
});

export const { logOut, signInLaravel, setUser } = AuthSlice.actions;

export default AuthSlice.reducer;

