import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {getCards, getMarkups, setActiveAvailability, setActiveCategory} from 'store/components/PrizesSlice';

import Cards from './assets/components/cards/Cards';
import HeaderPrizes from './assets/components/headerPrizes/HeaderPrizes';

import './Prizes.scss';

const Prizes = () => {
  const user = useAppSelector(state => state.auth.user);

  const dispatch = useAppDispatch();
  const category = useAppSelector((state) => state.prizes.categories);
  const {prizes: cardsLoading} = useAppSelector((state) => state.prizes.loading);
  const searchQuery = useAppSelector((state) => state.prizes.searchQuery);
  const purse = useAppSelector(state => state.dashboard.purse);
  const cards = useAppSelector(state => state.prizes.cards);
  const total = useAppSelector(state => state.prizes.cardsTotal);
  const availability = useAppSelector(state => state.prizes.availability);
  const [step, setStep] = useState(0);

  const stepLen = 24;

  if (!user) {
    return null;
  }

  useEffect(() => {
    const scrollTarget = document.getElementById('body');
    const topOffset = 0;

    const elementPosition = scrollTarget && scrollTarget.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition - topOffset;

    window.scrollBy({
      top: offsetPosition || 0,
      behavior: 'smooth'
    });

  }, [user]);

  useEffect(() => {
    if (Object.keys(cards).length < total && (step * stepLen <= total)) {
      dispatch(getCards({from: step * stepLen, to: (step + 1) * stepLen}));
    }
  }, [step]);

  useEffect(() => {
    setStep(0);
    dispatch(getCards({from: 0, to: stepLen}));
  }, [category, availability]);

  useEffect(() => {
    if (!cardsLoading && (searchQuery && searchQuery?.length > 2)) {
      setTimeout(() => {
        setStep(0);
        dispatch(getCards({from: 0, to: stepLen}));
      }, 500);
      return;
    }
    if (!cardsLoading && (searchQuery === '')) {
      setTimeout(() => {
        setStep(0);
        dispatch(getCards({from: 0, to: stepLen}));
      }, 500);
      return;
    }
  }, [searchQuery]);

  useEffect(() => {
    setStep(0);
    if (category.length) {
      dispatch(setActiveCategory(0));
    }
    dispatch(setActiveAvailability(0));
    dispatch(getCards({from: 0, to: stepLen, mode: true})).then(() => {
      dispatch(getMarkups());
    });
  }, [purse]);

  return (
    <div className={'prizes'}>
      <HeaderPrizes />
      <Cards step={step} setStep={setStep} />
    </div>
  );
};

export default Prizes;
