import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useAppDispatch} from 'utils/hooks';

import {IStore} from 'store/store';
import {showPopup} from 'store/components/PopupSlice';
import {limiter} from 'utils/limiter';
import {updateProfile} from 'store/components/ProfileSlice';
import {createOrder} from 'store/components/DashboardSlice';

import UserField from '../profile/assets/components/userField/UserField';
import Title from 'components/title/Title';
import SecondInput from 'components/secondInput/SecondInput';
import VerifiedEmail from 'components/verifiedEmail/VerifiedEmail';
import Button from 'components/button/Button';

import arrow from './assets/images/arrow.svg';

import './PlaceOrder.scss';

const PlaceOrder = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useSelector((state: IStore) => state.auth.user);
  const is_required_email = useSelector((state: IStore) => state.auth.is_required_email);

  if (!user) {
    return null;
  }

  const userName = user.name.slice(0, user.name.indexOf(' '));
  const userSecondName = user.name.slice(user.name.indexOf(' ') + 1);

  const [name, setName] = useState<string>(userName || '');
  const [secondName, setSecondName] = useState<string>(userSecondName || '');
  const [email, setEmail] = useState<string>(user.email || '');
  const [phone, setPhone] = useState<string>(user.phone || '');
  const [code, setCode] = useState<string>( '');
  const [loading, setLoading] = useState<boolean>( false);

  const [codeWithApi, setCodeWithApi] = useState<string>( '');
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [errorPhone, setErrorPhone] = useState<string>('');
  const [visibleInputCode, setVisibleInputCode] = useState<boolean>(false);

  const disabledButton =
      (name && secondName && phone) === ''
      || (email === '' && is_required_email)
      || phone.includes('_')
      || (!user.status && is_required_email)
      || ((email !== user.email));
  const fullName = `${name} ${secondName}`;

  const goBack = () => {
    history.push('cart');
  };

  const openPopup = () => {
    dispatch(showPopup('goToAccount'));
  };

  const placeOrder = () => {
    setLoading(true);
    dispatch(createOrder()).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!user.status && is_required_email) {
      setErrorEmail('Обязательное поле для оформления заказа');
    }
  }, []);

  return (
    <div className={'place-order'}>
      <div className={'place-order__container'}>
        <div className={'place-order__step-back-container'} onClick={goBack}>
          <img src={arrow} alt="arrow" className={'place-order__arrow'}/>
          <span className={'place-order__step-back'}>Шаг назад</span>
        </div>
        <Title>Оформление заказа</Title>
        <h3 className={'place-order__subtitle'}>
          Данные для отправки электронного подарочного сертификата
        </h3>
        <div>
          <SecondInput
            value={name}
            setValue={(value: string) => limiter(value, setName, 50)}
            marginBottom={16}
            title={'Имя'}
          />
          <SecondInput
            value={secondName}
            setValue={(value: string) => limiter(value, setSecondName, 50)}
            marginBottom={16}
            title={'Фамилия'}
          />
          <div>
            <div className={'profile-info__email'}>
              <UserField
                title={'Почта'}
                isMask={false}
                data={user.email}
                onClick={openPopup}
              />
              {user.email && is_required_email
                ? (<VerifiedEmail />)
                : null
              }
            </div>
            <UserField
              title={'Телефон'}
              isMask={true}
              data={user.phone}
              onClick={openPopup}
            />
          </div>
        </div>
        <Button
          onClick={placeOrder}
          loading={loading}
          disabled={disabledButton || loading}
          className={'place-order__button'}>
          Оформить заказ
        </Button>
      </div>
    </div>
  );
};

export default PlaceOrder;
