import React, {createRef, useEffect, useRef, useState} from 'react';

import {useAppSelector} from 'utils/hooks';
import {lazyLoading} from 'utils/functions';

import StatusHistory from '../status/StatusHistory';
import {GetIcon} from 'store/enums/normalizedIcons';
import Status from './assets/components/status/Status';
import Button from 'components/button/Button';
import UpwardButton from 'components/upwardButton/UpwardButton';

import './PullBasket.scss';

const PullBasket = () => {
  const liRef = useRef<any>();
  const observerLoader = useRef<any>();
  const lastItem = createRef<any>();

  const {history} = useAppSelector(state => state.prizes.loading);
  const historyOrders = useAppSelector(state => state.dashboard.historyOrders);
  const colors = useAppSelector((state) => state.settings.colors);
  const domain = useAppSelector((state) => state.settings.currentDomainName);

  const [step, setStep] = useState(1);
  const restrictionHistoryLen = window.innerWidth > 540 ? historyOrders.length : 5;
  const [restrictionHistory, setRestrictionHistory] = useState(historyOrders.slice(0, restrictionHistoryLen));
  const [scroll, setScroll] = useState<number>(Math.round(
    document.documentElement.scrollHeight / (liRef.current?.offsetHeight || 300)
  ));

  const visibleButton = historyOrders.length >= 6 && restrictionHistory.length < historyOrders.length;
  const disabledNewCard = step * restrictionHistoryLen >= historyOrders.length;
  const {loadButtonColor} = colors[domain];

  useEffect(() => {
    lazyLoading(lastItem, observerLoader, setScroll, 2);
  }, [lastItem]);

  if (!historyOrders) {
    return null;
  }

  useEffect(() => {
    setRestrictionHistory(historyOrders.slice(0, restrictionHistoryLen * step));
  }, [step]);

  return (
    <div className={history ? 'pull-basket pull-basket_hide' : 'pull-basket'}>
      <h3 className={'pull-basket__title'}>История оформления заказов</h3>
      <div className={'pull-basket__status-notification'}>
        <GetIcon
          iconName={'InfoIcon'}
          fill={colors[domain].infoIcon}
          secondaryFill={colors[domain].infoIcon}
          width={'24'}
        />
        <div>Статусы заказов обновляются каждые 3 минуты</div>
      </div>
      <ul className={'pull-basket__history'}>
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          restrictionHistory.slice(0, scroll + 2).map(({id, order}, index) => (
            <li
              ref={scroll - 1 === index ? lastItem : liRef}
              className={
                index < scroll
                  ? 'pull-basket__order pull-basket__order_active'
                  : 'pull-basket__order'
              }
              key={index}>
              <p className={'pull-basket__id-and-date'}>
                №{order.number} заказа – от {new Date(order.created_at).toLocaleDateString()}
                <br/>
                {order.is_changed ? (<span className={'pull-basket__is-changed'}>Заказ изменен</span>) : null}
              </p>
              <p className={'pull-basket__order-sum'}>
                Сумма заказа:{' '}
                <span className={'pull-basket__order-sum_bold'}>{order.sum}</span>
              </p>
              <table className={'pull-basket__cards-container'}>
                <thead className={'pull-basket__table-head_desktop'}>
                  <tr>
                    <td className={'pull-basket__cards-title'}>Состав заказа</td>
                    <td></td>
                    <td></td>
                    <td className={'pull-basket__cards-number'}>Номинал</td>
                    <td className={'pull-basket__cards-number'}>
                      {order.positions.findIndex((item) => {
                        return item.markup_nominal > 0;
                      }) >= 0 ? (
                          'Комиссия'
                        ) : null}
                    </td>
                    <td className={'pull-basket__cards-title'}>Статус заказа</td>
                  </tr>
                </thead>
                {
                  order.positions.map((position, index) => {
                    const pos = {
                      ...position
                    };
                    // if (order.status === 4) {
                    //   pos.status = 4;
                    // }

                    return (
                      <tbody key={position.id}>
                        <tr className={'pull-basket__card'}>
                          <td
                            className={`
                              pull-basket__card-id
                              ${position.status_title === 'Отказ' ? 'pull-basket__card-id_red' : ''}
                            `}>
                            <span className={'pull-basket__card-id_black'}>
                              №{index + 1}
                            </span>
                            {position.is_changed ? <span className={'pull-basket__position-is-changed'}>Новая позиция</span> : null}
                            <div
                              className={`
                                pull-basket__card-title 
                                pull-basket__card-title-container 
                                pull-basket__card-title-container_mobile
                              `}>
                              <span
                                className={`
                                pull-basket__card-title_black
                                ${position.status_title === 'Отказ' ? 'pull-basket__card-title_red' : ''}
                                `}>
                                {position.name}
                              </span>
                            </div>
                          </td>
                          <td className={'pull-basket__card-title'}>
                            <div
                              className={`
                                pull-basket__card-title-container_desktop  
                                pull-basket__card-title-container
                              `}>
                              <span
                                className={`
                                pull-basket__card-title_black
                                ${position.status_title === 'Отказ' ? 'pull-basket__card-title_red' : ''}
                              `}>
                                {position.name}
                              </span>
                            </div>
                          </td>
                          <td className={'pull-basket__card-number'}/>
                          <td
                            className={`
                              pull-basket__card-sum 
                              pull-basket__card-sum_desktop
                              ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                              ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
                            `}>
                            {position.nominal} ₽
                          </td>
                          <td
                            className={`
                              pull-basket__card-sum 
                              pull-basket__card-sum_desktop
                              ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                              ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
                            `}>
                            {
                              position.markup_nominal
                                ? (
                                  <span>
                                    {position.markup_nominal} ₽
                                  </span>
                                )
                                : null
                            }
                          </td>
                          <td className={'pull-basket_mobile'}/>
                          <td
                            className={`
                              pull-basket__card-sum 
                              pull-basket__card-sum_desktop
                              ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                              ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
                            `}>
                            <StatusHistory
                              type={'orders'}
                              status={pos.status}
                              is_changed={position.is_changed}
                              link={position.link}
                              id={position.id}
                              status_label={position.status_title}
                            />
                          </td>
                        </tr>
                        <tr className={'pull-basket_mobile-row'}>
                          <td>
                            <span className={'pull-basket__card-sum-title'}>
                              Номинал
                            </span>
                            <span
                              className={`
                                pull-basket__card-sum 
                                pull-basket__card-sum_mobile
                                ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
                                ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                              `}>
                              {position.sum} ₽
                            </span>
                          </td>
                          <td>
                            {position.markup_nominal ? (
                              <>
                                <span className={'pull-basket__card-sum-title'}>
                                  Комиссия
                                </span>
                                <span
                                  className={`
                                    pull-basket__card-sum 
                                    pull-basket__card-sum_mobile
                                    ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
                                    ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                                  `}>
                                  {position.markup_nominal} ₽
                                </span>
                              </>
                            ) : null}
                          </td>
                          <td className={'pull-basket__status'}>
                            <span className={'pull-basket__status-title_mobile'}>
                                Статус заказа
                            </span>

                            <StatusHistory
                              type={'orders'}
                              status={pos.status}
                              is_changed={position.is_changed}
                              id={position.id}
                              link={position.link}
                              status_label={position.status_title}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                }
              </table>
            </li>
          ))
        }
      </ul>
      {
        visibleButton ? (
          <div className={'pull-basket__button-container'}>
            <Button
              disabled={disabledNewCard}
              className={'load-button'}
              icon={<GetIcon iconName={'LoadButton'} fill={loadButtonColor} secondaryFill={loadButtonColor} />}
              onClick={() => setStep(step + 1)}>
              Показать еще
            </Button>
          </div>
        ) : null
      }
      <UpwardButton />
    </div>
  );
};

export default PullBasket;
