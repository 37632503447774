import React, {useEffect, useState} from 'react';

import {useAppDispatch} from 'utils/hooks';

import {getCode} from 'store/components/AuthSlice';

import Button from 'components/button/Button';

import './Timer.scss';

interface ITimer {
  phone: string;
}

const Timer: React.FC<ITimer> = ({phone}) => {
  const dispatch = useAppDispatch();

  const variantSMS = 'в сообщении';
  const variantCall = 'по звонку';

  const [sendCodeType, setSendCodeType] = useState(true);
  const [timer, setTimer] = useState<number>(61);

  const getCodeRepeat = (sendType: boolean) => {
    const data = {
      phone,
      send_code: !sendType ? 'sms' : undefined
    };
    setTimer(61);
    dispatch(getCode(data));
  };

  const changeVariantSendCode = () => {
    setSendCodeType(!sendCodeType);
    getCodeRepeat(!sendCodeType);
  };

  useEffect(() => {
    const counter =
      setInterval(() => setTimer(timerInner => timerInner - 1), 1000);

    if (timer < 0) {
      clearInterval(counter);
    }

    return () => clearInterval(counter);
  }, [timer]);

  return (
    <div className={'timer'}>
      <p className={'timer__title'}>Не получили код?</p>
      {
        timer > 0 ? (
          <p className={'timer__counter'}>
             Отправить код повторно можно будет через 0:{timer < 10 ? `0${timer}` : timer}
          </p>
        ) : (
          <>
            <a
              href="#"
              className={
                timer < 0
                  ? 'timer__link timer__link_active'
                  : 'timer__link'
              }
              onClick={() => getCodeRepeat(sendCodeType)}>
              Отправить еще раз
            </a>
            <Button
              width={'100%'}
              className={'timer__button'}
              backgroundColor={'#fff'}
              color={'#4661c4'}
              onClick={changeVariantSendCode}>
              Получить код {sendCodeType ? variantSMS : variantCall}
            </Button>

          </>
        )
      }
    </div>
  );
};

export default Timer;
