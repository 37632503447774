import React from 'react';
import Checkbox from '../checkbox/Checkbox';

interface OwnProps {
  currentDomainName: string;
  checkbox: boolean;
  setCheckbox: (value: boolean) => void;
  showCheckBox?: boolean;
}

const RulesCheckbox = ({currentDomainName, checkbox, setCheckbox, showCheckBox = true}: OwnProps) => {
  return (
    <div className={`${currentDomainName}__check-rules ${currentDomainName}__birthday-none`}>
      {
        showCheckBox
          ? (
            <Checkbox
              className={
                `${currentDomainName}__checkbox 
                ${checkbox ? `${currentDomainName}__checkbox_active` : ''}`
              }
              checkbox={checkbox}
              onClick={() => setCheckbox(!checkbox)}
            />
          ) : null
      }
      <div className={`${currentDomainName}__rules-and-pers`}>
        При входе или активации вы соглашаетесь
        <br/>
        {
          currentDomainName.includes('mtsfirst') && (
            <>
              <a href="/programRules.docx" className={`${currentDomainName}__rules-and-pers_color-gray`}>
                с Правилами мотивационной программы
              </a>
            </>
          )
        }
        {
          currentDomainName.includes('raiffeisen') && (
            <>
              <a href="/raifRules.pdf" target={'_blank'} className={`${currentDomainName}__rules-and-pers_color-gray`}>
                с Правилами активации
              </a>
              <br/>
            </>
          )
        }
        {
          currentDomainName.includes('geo') && (
            <>
              <a href="/geoRules.pdf" className={`${currentDomainName}__rules-and-pers_color-gray`}>
                с Правилами использования
              </a>
              <br/>
            </>
          )
        }
        {
          currentDomainName === 'makfa' || currentDomainName === 'birthday' && (
            <>
              <a href="/makfaRules.pdf" className={`${currentDomainName}__rules-and-pers_color-gray`}>
                с Правилами мотивационной программы
              </a>
              <br/>
            </>
          )
        }
        {currentDomainName.includes('sargaz')
        || currentDomainName.includes('proektirovanie')
        || currentDomainName.includes('womens-day')
        || currentDomainName.includes('aclub')
        || (currentDomainName.includes('loto') && currentDomainName.includes('pr'))
          ? (
            <>
              <a
                onClick={() => open(
                  currentDomainName.includes('proektirovanie')
                    ? '/rulesProektirovanie.pdf'
                    : '/Правила активации.pdf'
                )}
                target={'_self'}
                className={'auth__rules-and-pers_color-blue'}>
                с Правилами&nbsp;активации,
              </a>
              <span>,&nbsp;</span>
              <br/>
              <a
                onClick={() => open('/Политика конфиденциальности-подпись.pdf')}
                target={'_blank'}
                className={'auth__rules-and-pers_color-blue'}>
                Политикой&nbsp;конфиденциальности,
              </a>
              <span>,&nbsp;</span>
              <br/>
              <a
                onClick={() => open('/Правила обмена.pdf')}
                target={'_blank'}
                className={'auth__rules-and-pers_color-blue'}>
                Правилами&nbsp;обмена,
              </a>
              {' '}и{' '}
              <a
                onClick={() => open('/Тарифы на обмен.pdf')}
                target={'_blank'}
                className={'auth__rules-and-pers_color-blue'}>
                Тарифами на обмен
              </a>
              <br/>
            </>
          )
          : (
            <>
              <a href="/Политика конфиденциальности-подпись.pdf" target={'_blank'} className={`${currentDomainName}__rules-and-pers_color-gray`}>
               c Политикой конфиденциальности
              </a>
              &nbsp;и&nbsp;
              <a href="/Правила активации.pdf" target={'_blank'} className={`${currentDomainName}__rules-and-pers_color-gray`}>
                Условиями использования
              </a>
            </>
          )
        }
      </div>
    </div>
  );
};

export default RulesCheckbox;
